import React from 'react';
import Main from './components/Main';
import { StoreProvider } from './store/Provider';

const App: React.FC = () => {
  return (
    <StoreProvider>
      <Main/>
    </StoreProvider>
  );
}

export default App;
