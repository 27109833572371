import ProfilEdit from "./ProfilEdit"

const ProfilPage: React.FC = ()=>{

    return <>
    <ProfilEdit/>
    </>;
}

export default ProfilPage;
