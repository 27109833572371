import { Tournament } from "../../modules/tournaments/model/tournament.model";
import StandardModal, { StandardModalProps } from "../modal/StandardModal";
import { useTranslate } from "react-translate";
import { ModerationsService } from "../../modules/moderations/ModerationsService";

export type ParticipantRemoveModalData={
    userId:number,
    userName:string,
}

const ParticipantRemoveModal: React.FC<StandardModalProps&{
    data:ParticipantRemoveModalData|null,
    tournament:Tournament
}>=(props)=>{

    const {data,onClose,show,tournament} = props;
    const t = useTranslate("participantsTable");

    if(!data){
        return null;
    }

    const participantRemove = ()=>{
        const moderationService = ModerationsService.get();
        moderationService.participantRemove(tournament._id,data.userId)
    }

    return (
        <StandardModal
        show={show}
        onExit={onClose}
        onSave={()=>{
            onClose();
            participantRemove();
        }}
        saveEnabled={true}
        title={t("REMOVE_PARTICIPANT_MODAL_HEADER")}
        >
            {t("REMOVE_PARTICIPANT_MODAL_DESC",{username:data.userName,tournamentName:tournament.name})}
        </StandardModal>);
}

export default ParticipantRemoveModal;

