export default {
    "TABLE_HEADER":"Turnieje",
    "TABLE_DESC":"Wszystkie turnieje w typerze.",

    "NAME":"Nazwa",
    "SLUG":"Link",
    "TIME_START":"Czas rozpoczęcia",
    "TIME_END":"Czas zakończenia",
    "MODERATORS":"Liczba moderatorów",
    "INVITED":"Liczba zaproszonych",
    "PARTICIPANTS":"Liczba uczestników",

    "CREATE_TOURNAMENT_MODAL_HEADER":"Utwórz turniej",
    "CREATE_TOURNAMENT_MODAL_DESC":"Utwórz nowy turniej.",
    "CREATE_TOURNAMENT_MODAL_INPUT_NAME":"Nazwa turnieju",
    "CREATE_TOURNAMENT_MODAL_INPUT_POINTSFORWINNER":"Punkty za wygraną",
    "CREATE_TOURNAMENT_MODAL_INPUT_POINTSFORSCORE":"Punkty za wynik",

    "DELETE_TOURNAMENT_MODAL_HEADER":"Usuń turniej",
    "DELETE_TOURNAMENT_MODAL_DESC":"Usuń turniej {{name}}.",

}