import { Action } from 'redux';

interface Dictionary<T> { 
	[key: string]: T; 
}

type ReducerAction<Data> = Action<string> & {data:Data}

export function callAction<Data>(type: string, data: Data): ReducerAction<Data> {
	return { type, data };
}

export abstract class ReducerCollection<State> {
	protected actions: Dictionary<(state: State, action?: any) => State> = this.createActions();
	protected initialState: State = this.initState();

	public changeState<Data>(
		state: State = this.initialState,
		action: ReducerAction<Data> = (({ type: '', data: undefined }) as unknown as ReducerAction<Data>)
	): State {
		if (action.type && this.actions[action.type]) {
			return this.actions[action.type](state, action.data);
		}

		return state;
	}

	protected abstract createActions(): Dictionary<(state: State, action?:any) => State>;

	protected abstract initState(): State;
}
