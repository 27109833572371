import Dexie from "dexie";

const db = new Dexie("typer2_toast-messages");
db.version(1).stores({
    data:'++id, toastId'
})

export const addReadedToastMessage = async (toastId:string)=>{
    await db.table("data").add({toastId})
}

export const getReadedToastMessages=async ():Promise<Array<string>>=>{
    const dataQuery = await db.table("data").toArray() as Array<{id:number,toastId:string}>
    return dataQuery.map(obj=>obj.toastId);

}