import { LoadedAdminState } from "../../modules/admin/store";
import { useTypedSelector } from "../../store/root";
import { useTranslate } from "react-translate";
import { useMemo, useState } from "react";
import { useTable } from "react-table";

import deleteIcon from '../../assets/admin-panel/icons/trash-fill.svg'
import addIcon from '../../assets/admin-panel/icons/plus-square-fill.svg'
import CreateTournamentModal from "./CreateTournamentModal";
import DeleteTournamentModal, { DeleteTournamentModalData } from "./DeleteTournamentModal";
import { timestampToDisplay } from "../../utils/time";

const TournamentTable: React.FC = () => {
  const { tournaments } = useTypedSelector(
    (state) => (state.admin as LoadedAdminState).adminData
  );
  const t = useTranslate("tournamentsTable");

  const [showCreateTournamentModal,setShowCreateTournamentModal] = useState<boolean>(false);

  const [showDeleteTournamentModal,setShowDeleteTournamentModal] = useState<boolean>(false);
  const [deleteTournamentModalData,setDeleteTournamentModalData] = useState<DeleteTournamentModalData|null>(null);

  const columns = useMemo(
    () => [
      {
        Header: t("NAME"),
        accessor: "name",
      },
      {
        Header: t("SLUG"),
        accessor: "slug",
      },
      {
        Header: t("TIME_START"),
        accessor: "timeStart",
        Cell: (obj: any) => !(obj.value)? "" : String(`${timestampToDisplay(obj.value)}`),
      },
      {
        Header: t("TIME_END"),
        accessor: "timeEnd",
        Cell: (obj: any) => !(obj.value)? "" : String(`${timestampToDisplay(obj.value)}`),
      },
      {
        Header: t("MODERATORS"),
        accessor: "moderators",
      },
      {
        Header: t("INVITED"),
        accessor: "invited",
      },
      {
        Header: t("PARTICIPANTS"),
        accessor: "participants",
      },
    ],
    [t]
  );

  const data = useMemo(()=>
    tournaments.map(tournament=>({
        name:tournament.name,
        slug:tournament.slug,
        timeStart:tournament.time_start,
        timeEnd:tournament.time_end,
        moderators:tournament.moderators.length,
        invited:tournament.invited.length,
        participants:tournament.participants.length,
        _id:tournament._id
    }))
  ,[tournaments])

  const tableInstance = useTable({
    columns:columns as any,
    data,
  })

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
} = tableInstance;


  return (
    <div>
      <h3>
        {t("TABLE_HEADER")}
      </h3>
      <p>
        {t("TABLE_DESC")}
      </p>

        <table className="table" {...getTableProps()}>
            <thead>
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()} scope="col">
                    {// Render the header
                    column.render('Header')}

                    {/* Add a sort direction indicator */}
                    </th>
                ))}
                <th>
                    
                </th>
                </tr>
            ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
            rows.map(row => {
                // Prepare the row for display
                prepareRow(row)
                return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                    {// Loop over the rows cells
                    row.cells.map(cell => {
                    // Apply the cell props
                    return (
                        <td {...cell.getCellProps()}>
                        {// Render the cell contents
                        cell.render('Cell')}
                        </td>
                    )
                    })}
                    <td style={{cursor:"pointer"}} onClick={()=>{
                      setDeleteTournamentModalData({tournamentName:row.original.name,tournamentId:row.original._id})
                      setShowDeleteTournamentModal(true);
                    }}>
                        <img src={deleteIcon} alt="edit"/>
                    </td>
                </tr>
                )
            })}
            </tbody>
        </table>    
        <div style={{margin:8,marginRight:30,textAlign:"right"}}>
            <img onClick={()=>setShowCreateTournamentModal(true)} style={{cursor:"pointer",height:24,width:24}} src={addIcon} alt="add"/>
        </div>
        
        <CreateTournamentModal
        show={showCreateTournamentModal}
        onClose={()=>setShowCreateTournamentModal(false)}
        />

        <DeleteTournamentModal
        show={showDeleteTournamentModal}
        onClose={()=>setShowDeleteTournamentModal(false)}
        data={deleteTournamentModalData}
        />

    </div>);
};

export default TournamentTable;
