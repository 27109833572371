import { AdminState } from "."

export type SetAdminInfoPayload={
    admin:false,
}|{
    admin:true,
    superuser:boolean,
}

export const setAdminInfo = (state:AdminState,payload:SetAdminInfoPayload):AdminState=>{
    const {adminDataLoaded}:{adminDataLoaded:false} = state as any;
    //spaghetti, bc typescript is bugged
    const {admin} = payload;
    if(!admin){
        return payload as AdminState;
    }

    return{
        ...state,
        ...payload,
        adminDataLoaded:!!(adminDataLoaded),
    }
}