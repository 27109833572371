import { useLocation } from "react-router";
import { useTypedSelector } from "../../store/root";
import { isTournamentPage } from "../../utils/navbar";
import {useTranslate} from 'react-translate';
import { useMemo } from "react";
import { useTable } from "react-table";
import { BetResultType, getBetColor } from "../../utils/betColors";
import { sortScoreboard } from "./sortScoreboard";

const ScoreboardTable: React.FC = ()=>{

    const location = useLocation();
    const tournamentPageData = useMemo(()=>isTournamentPage(location.pathname),[location]);
    const tournaments = useTypedSelector(state=>state.tournaments.tournamentsParticipate);
    const users = useTypedSelector(state=>state.tournaments.users);
    const t = useTranslate("scoreboard");

    const tournament = useMemo(()=>tournaments.find(tournament=>tournament.slug===(tournamentPageData as any).tournamentSlug as string),[tournaments,tournamentPageData]);
    const scoreboard = useMemo(()=>(tournament?.scoreboard || []),[tournament]);


    const columns = useMemo(
        () => [
          {
            Header: t("COLUMN_POSITION"),
            accessor: 'position',
            Cell: (obj: any) => String(`${obj.value}.`),

          },
          {
            Header: t("COLUMN_DISPLAY"),
            accessor: 'display',
          },
          {
            Header: t("COLUMN_WINNERS"),
            accessor: 'winners',
          },
          {
            Header: t("COLUMN_SCORES"),
            accessor: 'scores',
          },
          {
            Header: t("COLUMN_POINTS"),
            accessor: 'points',
          },
        ],
        [t]
      )

      const data = useMemo(()=>{
        const orderedScoreboard = sortScoreboard(scoreboard);
        return orderedScoreboard.map((score,i)=>{
        const user = users.find(user=>user._id===+score.user)
        let positionIndex:number=i;

        while(positionIndex>0){
            if(score.points<orderedScoreboard[positionIndex-1].points){
                break;
            }
            --positionIndex;
        } 
        let position = positionIndex+1;
        return{
            position,
            display:user?.display,
            winners:score.winners,
            scores:score.scores,
            points:score.points
        }
       }
       ) 
    },[users,scoreboard])


      const tableInstance = useTable({
        columns:columns as any,
        data,
      })
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    return (
        
        <div>
          <h3>
            {t("TABLE_HEADER")}
          </h3>
          <p>
            {t("TABLE_DESC",{tournamentName:tournament?.name})}
          </p>

            <table className="table" {...getTableProps()}>
                <thead>
                {// Loop over the header rows
                headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {// Loop over the headers in each row
                    headerGroup.headers.map(column => (
                        // Apply the header cell props
                        <th {...column.getHeaderProps()} scope="col">
                        {// Render the header
                        column.render('Header')}

                        {/* Add a sort direction indicator */}
                        </th>
                    ))}
                    </tr>
                ))}
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                {// Loop over the table rows
                rows.map((row,i) => {
                    // Prepare the row for display
                    prepareRow(row)

                    const backgroundColor = getBetColor(i%2===0,row.original.position===1 ? BetResultType.SCORE : row.original.position<=3 ? BetResultType.WINNER : BetResultType.NORMAL);

                    return (
                    // Apply the row props
                    <tr style={{backgroundColor}} {...row.getRowProps()}>
                        {// Loop over the rows cells
                        row.cells.map(cell => {
                        // Apply the cell props
                        return (
                            <td {...cell.getCellProps()}>
                            {// Render the cell contents
                            cell.render('Cell')}
                            </td>
                        )
                        })}
                    </tr>
                    )
                })}
                </tbody>
            </table>    
        </div>
    );
}

export default ScoreboardTable;