import { Team } from "../models/team.model";
import { callAction, ReducerCollection } from "../../../store/core";
import { addTeam, AddTeamPayload } from "./addTeam";
import { deleteTeam, DeleteTeamPayload } from "./deleteTeam";

export type TeamsState = {
    teams: Array<Team>
}

const initialState={
    teams:[]
}

enum Actions {
    ADD_TEAM = "ADD_TEAM",
    DELETE_TEAM = "DELETE_TEAM"
}

export class TeamsReducer extends ReducerCollection<TeamsState>{

    protected createActions(){
        return{
            [Actions.ADD_TEAM]:addTeam,
            [Actions.DELETE_TEAM]:deleteTeam,
        }
    }

    protected initState(){
        return initialState;
    }
}

//to jest jedyne miejsce, w ktorym w tej architekturze trzeba ręcznie przekopiować typ :(

export function callAddTeam(data:AddTeamPayload){
    return callAction(Actions.ADD_TEAM,data)
}

export function callDeleteTeam(data:DeleteTeamPayload){
    return callAction(Actions.DELETE_TEAM,data)
}