import StandardModal, { StandardModalProps } from "../modal/StandardModal";
import { useTranslate } from "react-translate";
import { useState } from "react";
import { AuthService } from "../../modules/auth/auth.service";

const LoginModal:React.FC<StandardModalProps> = (props)=>{

    const { show, onClose } = props;
    const t = useTranslate("navbar");
  
    const [username, setUsername] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [rememberMe, setRememberMe] = useState<boolean>(false);

    const login=()=>{
        const authService = AuthService.get();
        authService.loginLocal(username,password,rememberMe).then(result=>{
          if(!result){
            alert(t("LOGIN_MODAL_WRONG_PASSWORD"))
          }
        });
    }

    return (
        <StandardModal
      show={show}
      onExit={onClose}
      onSave={() => {
        onClose();
        login();
        setPassword("");
        setUsername("");
      }}
      saveEnabled={true}
      title={t("LOGIN_MODAL_HEADER")}
      save={t("LOGIN_MODAL_LOGIN")}
    >


<p>{t("LOGIN_MODAL_DESC")}</p>
      <div className="input-group mb-3">
        <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
          {t("LOGIN_MODAL_INPUT_USERNAME")}
        </span>
        <input
          value={username}
          onChange={(e)=>setUsername(e.target.value)}
          type="text"
          className="form-control"
          id="basic-url"
          aria-describedby="basic-addon3"
        />
      </div>
      <div className="input-group mb-3">
        <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
        {t("LOGIN_MODAL_INPUT_PASSWORD")}

        </span>
        <input
          value={password}
          onChange={(e)=>setPassword(e.target.value)}
          type="password"
          className="form-control"
          id="basic-url"
          aria-describedby="basic-addon3"
        />
      </div>

      <div className="form-check">
        <input className="form-check-input" type="checkbox" onClick={()=>{setRememberMe(curr=>!curr)}} checked={rememberMe} id="flexCheckChecked" />
        <label className="form-check-label">
        {t("LOGIN_MODAL_INPUT_REMEMBERME")}
        </label>
        </div>
    </StandardModal>
    );
}

export default LoginModal;