export default {
    "SELECT_TOURNAMENT_DESC":"Wybierz turniej",
    "EDIT_TOURNAMENT":"Edytuj turniej",

    "TOURNAMENT_ID":"Id",
    "TOURNAMENT_SLUG":"Link",
    "TOURNAMENT_POINTFORSCORE":"Punkty za wynik",
    "TOURNAMENT_POINTFORWINNER":"Punkty za zwycięzcę",
    "TOURNAMENT_MODERATORS":"Moderatorzy",
    "TOURNAMENT_PARTICIPANTS":"Uczestnicy",
    "TOURNAMENT_FINISHED":"Zakończony",
    "TOURNAMENT_FINISHED_YES":"Tak",
    "TOURNAMENT_FINISHED_NO":"Nie",
    "TOURNAMENT_TIMESTART":"Czas rozpoczęcia",
    "TOURNAMENT_TIMEEND":"Czas zakończenia",

    "TOURNAMENT_NAME":"Nazwa turnieju",
    "UPDATE_TOURNAMENT_MODAL_HEADER":"Edytuj turniej",
    "UPDATE_TOURNAMENT_MODAL_DESC":"Edytuj turniej {{tournamentName}}.",

}