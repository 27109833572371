import { callAction, ReducerCollection } from "../../../store/core";
import { User } from "../../auth/model/user-data.model";
import { Tournament } from "../../tournaments/model/tournament.model";
import { setAdminData, SetAdminDataPayload } from "./setAdminData";
import { setAdminInfo, SetAdminInfoPayload } from "./setAdminInfo";

export type AdminState={
    admin:false,
}|{
    admin:true,
    superuser:boolean,
    adminDataLoaded:false,
}|{
    admin:true,
    superuser:boolean,
    adminDataLoaded:true,
    adminData:{
    tournaments:Array<Tournament>,
    users:Array<User>
    }
}

export type LoadedAdminState ={
    admin:true,
    superuser:boolean,
    adminDataLoaded:true,
    adminData:{
    tournaments:Array<Tournament>,
    users:Array<User>
    }
}

const initialState:AdminState={
    admin:false
}

enum Actions {
    "SET_ADMIN_INFO"="SET_ADMIN_INFO",
    "SET_ADMIN_DATA"="SET_ADMIN_DATA"
}

export class AdminReducer extends ReducerCollection<AdminState>{

    protected createActions(){
        return{
            [Actions.SET_ADMIN_DATA]:setAdminData,
            [Actions.SET_ADMIN_INFO]:setAdminInfo
        }
    }
    protected initState(){
        return initialState;
    }
}

export const callSetAdminData = (payload:SetAdminDataPayload)=>{
    return callAction(Actions.SET_ADMIN_DATA,payload);
}

export const callSetAdminInfo = (payload:SetAdminInfoPayload)=>{
    return callAction(Actions.SET_ADMIN_INFO,payload);
}