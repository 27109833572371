import { AdminService } from "../../modules/admin/admin.service";
import StandardModal, { StandardModalProps } from "../modal/StandardModal"
import {useTranslate} from "react-translate";

export type DeleteTournamentModalData = {
    tournamentId:number,
    tournamentName:string
}

const DeleteTournamentModal: React.FC<StandardModalProps&{data:DeleteTournamentModalData|null}> = (props)=>{

    const {data,show,onClose} = props;
    const t = useTranslate("tournamentsTable");

    if(!data){
        return null;
    }

    const deleteTournament = (tournamentId:number)=>{
        const adminService = AdminService.get();
        adminService.tournamentDelete(tournamentId);
    }

    return (
        <StandardModal
        show={show}
        onExit={onClose}
        onSave={()=>{
            onClose();
            deleteTournament(data.tournamentId);
        }}
        saveEnabled={true}
        title={t("DELETE_TOURNAMENT_MODAL_HEADER")}
        >
            {t("DELETE_TOURNAMENT_MODAL_DESC",{name:data.tournamentName})}
        </StandardModal>
    );
}

export default DeleteTournamentModal;