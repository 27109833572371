import { Ref } from "react";

export const calculateStickyTopTranslate = (
  containerOffset: number,
  containerHeight: number,
  elementOffset: number,
  elementHeight: number,
  windowScrollY: number
) => {
  if (windowScrollY < elementOffset + containerOffset) {
    return 0;
  }

  console.log(containerOffset,containerHeight,elementOffset,elementHeight,windowScrollY)
  return Math.max(
    0,
    Math.min(
      containerHeight - (elementHeight + (elementOffset ))
    ,
    windowScrollY - (elementOffset + containerOffset))
  );
};

export const calculateIfInTableRange = (
    containerOffset: number,
    containerHeight: number,
    elementOffset: number,
    elementHeight: number,
    windowScrollY: number
  ) => {
    if (windowScrollY < elementOffset + containerOffset) {
      return false;
    }

    if(windowScrollY > elementOffset + containerOffset + elementHeight + containerHeight){
        return false
    }

    return true;
  
  };
  