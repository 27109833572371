import { TranslatorProvider } from "react-translate"
import { useTypedSelector } from "../store/root";
import translations from "./translations";

export const TranslationsProvider: React.FC = (props)=>{

    const {children} = props;
    const languageTag = useTypedSelector(state=>state.translate.languageTag);
    const translation = translations[languageTag];

    return(
        <TranslatorProvider translations={translation}>
            {children}
        </TranslatorProvider>
    )
}

export default TranslationsProvider;