import moment from "moment"

export const timestampToDatetime = (timestamp:number)=>{
    return moment.unix(timestamp).format("yyyy-MM-DDTHH:mm")
}

export const timestampToDisplay = (timestamp:number)=>{
    return moment.unix(timestamp).format("yyyy-MM-DD HH:mm")
}


export const datetimeToTimestamp = (datetime:Date)=>{
    return moment(datetime).unix()
}