import { useState } from "react";
import { useTranslate } from "react-translate";
import { AdminService } from "../../modules/admin/admin.service";
import { LoadedAdminState } from "../../modules/admin/store";
import { User } from "../../modules/auth/model/user-data.model";
import { Tournament } from "../../modules/tournaments/model/tournament.model";
import { useTypedSelector } from "../../store/root";
import StandardModal, { StandardModalProps } from "../modal/StandardModal";

const AddModerationModal: React.FC<StandardModalProps> = (props) =>{
    const {show,onClose} = props;
    const t =useTranslate("moderationsTable");

    const {tournaments,users} = useTypedSelector(state=>(state.admin as LoadedAdminState).adminData);

    const [userSlug,setUserSlug] = useState<string>("");
    const [tournamentSlug,setTournamentSlug] = useState<string>("");

    const addModeration = (tournamentSlug:string,userSlug:string,tournaments:Array<Tournament>,users:Array<User>)=>{

        const tournamentId = tournaments.find(tournament=>tournament.slug===tournamentSlug)?._id;
        const userId = users.find(user=>user.slug===userSlug)?._id;

        if(!tournamentId||!userId){
            return
        }

        const adminService = AdminService.get();
        adminService.moderationAdd(tournamentId,userId);
    }

    return (
        <StandardModal
        show={show}
        onExit={onClose}
        onSave={()=>{
            onClose();
            addModeration(tournamentSlug,userSlug,tournaments,users);
        }}
        saveEnabled={true}
        title={t("ADD_MODERATION_MODAL_HEADER")}
        >
        <p>{t("ADD_MODERATION_MODAL_DESC")}</p>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("ADD_MODERATION_MODAL_INPUT_USERSLUG")}
          </span>
          <input
            value={userSlug}
            onChange={(e)=>setUserSlug(e.target.value)}
            type="text"
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("ADD_MODERATION_MODAL_INPUT_TOURNAMENTSLUG")}
          </span>
          <input
            value={tournamentSlug}
            onChange={(e)=>setTournamentSlug(e.target.value)}
            type="text"
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>

        </StandardModal>
    );
}

export default AddModerationModal;