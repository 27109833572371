import { callAction, ReducerCollection } from "../../../store/core";
import { getLanguageCookie } from "../cache-saving.service";
import { AvailableLanguageTags } from "../models/available-language-tags"
import { changeLanguage, ChangeLanguagePayload } from "./changeLanguage";

export type TranslateState = {
    languageTag:AvailableLanguageTags
}

const initLanguageTag=getLanguageCookie();

const initialState:TranslateState={
    languageTag:initLanguageTag
}

enum Actions{
    "CHANGE_LANGUAGE"="CHANGE_LANGUAGE"
}

export class TranslateReducer extends ReducerCollection<TranslateState>{
    protected createActions(){
        return{
            [Actions.CHANGE_LANGUAGE]:changeLanguage
        }
    }
    protected initState(){
        return initialState;
    }
}

export const callChangeLanguage = (payload:ChangeLanguagePayload)=>{
    return callAction(Actions.CHANGE_LANGUAGE,payload);
}
