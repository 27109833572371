import { Tournament } from "../../modules/tournaments/model/tournament.model";
import { useTypedSelector } from "../../store/root";
import { useTranslate } from "react-translate";
import { timestampToDatetime,timestampToDisplay } from "../../utils/time";

const TournamentDesc: React.FC<{ tournament: Tournament | undefined }> = (
  props
) => {
  const { tournament } = props;
  const users = useTypedSelector((state) => state.tournaments.users);
  const t = useTranslate("modPanel");

  if (!tournament) {
    return null;
  }

  return (
    <div style={{margin:20,display:"flex",justifyContent:"center"}}>
      <div className="card" style={{ width: "18rem" }}>
        <div className="card-header">{tournament.name}</div>
        <ul className="list-group list-group-flush">
          <li className="list-group-item">
            {t("TOURNAMENT_ID")}:{tournament._id},<br />
          </li>
          <li className="list-group-item">
            {t("TOURNAMENT_SLUG")}:{tournament.slug}
          </li>
          <li className="list-group-item">
            {t("TOURNAMENT_POINTFORSCORE")}:{tournament.point_rules.score}
          </li>
          <li className="list-group-item">
            {t("TOURNAMENT_POINTFORWINNER")}:{tournament.point_rules.winner}
          </li>
          <li className="list-group-item">
            {t("TOURNAMENT_MODERATORS")}:
            {tournament.moderators.map((moderator, i) => (
              <span key={i}>
                {users.find((user) => user._id === moderator)?.display},{" "}
              </span>
            ))}
          </li>
          <li className="list-group-item">
            {t("TOURNAMENT_PARTICIPANTS")}:
            {tournament.participants.map((paticipant, i) => (
              <span key={i}>
                {users.find((user) => user._id === paticipant)?.display},{" "}
              </span>
            ))}
          </li>
          <li className="list-group-item">
            {t("TOURNAMENT_FINISHED")}:
            {tournament.finished
              ? t("TOURNAMENT_FINISHED_YES")
              : t("TOURNAMENT_FINISHED_NO")}
          </li>
          <li className="list-group-item">
            {t("TOURNAMENT_TIMESTART")}:{!tournament.time_start ? "" : timestampToDisplay(tournament.time_start)}
          </li>
          <li className="list-group-item">
            {t("TOURNAMENT_TIMEEND")}:{!tournament.time_end ? "" : timestampToDisplay(tournament.time_end)}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TournamentDesc;
