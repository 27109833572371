import { BrowserRouter, Route } from "react-router-dom";
import { BASENAME } from "../config/server";

const RouterProvider:React.FC = (props)=>{

    const {children} = props;

    return (
        
        <BrowserRouter basename={BASENAME}>
            {props.children}
        </BrowserRouter>
        
        );
}

export default RouterProvider;