import { AdminState } from ".";
import { User } from "../../auth/model/user-data.model";
import { Tournament } from "../../tournaments/model/tournament.model";

export type SetAdminDataPayload = {
    tournaments:Array<Tournament>,
    users:Array<User>
}

export const setAdminData = (state:AdminState,payload:SetAdminDataPayload):AdminState=>{
    const {admin} = state;
    const {tournaments,users} = payload;

    if(!admin){
        return state;
    }

    return{
        ...state,
        adminDataLoaded:true,
        adminData:{tournaments,users}
    }
}