import { Route } from "react-router";
import AdminPanel from "../admin-panel";
import BetsPage from "../bets-page";
import MatchesPage from "../matches-page";
import ModPanel from "../mod-panel";
import ProfilPage from "../profil-page";
import ScoreboardPage from "../scoreboard-page";
import { STANDARD_PAGES, TOURNAMENTS_PAGE, TOURNAMENT_PAGES } from "./Navbar";

console.log(STANDARD_PAGES.PROFIL)

export const PageSwitch:React.FC = ()=>{
    return(
        <>
        <Route
        path={`/${STANDARD_PAGES.ADMIN}`}
        component={AdminPanel}
        />
        <Route 
        path={`/${STANDARD_PAGES.MOD}`}
        component={ModPanel}
        />
        <Route
        path={`/${STANDARD_PAGES.PROFIL}`}
        component={ProfilPage}
        />
        <Route 
        path={`/${TOURNAMENTS_PAGE}/:tournamentSlug/${TOURNAMENT_PAGES.BETS}`}
        component={BetsPage}
        />
        <Route
        path={`/${TOURNAMENTS_PAGE}/:tournamentSlug/${TOURNAMENT_PAGES.SCOREBOARD}`}
        component={ScoreboardPage}
        />
        <Route
        path={`/${TOURNAMENTS_PAGE}/:tournamentSlug/${TOURNAMENT_PAGES.TABLE}`}
        component={MatchesPage}
        />
        </>
    )
}

export default PageSwitch;