import { callAction, ReducerCollection } from "../../../store/core";
import { ToastMessage } from "../models/toast-message.model";
import { pushToastMessage, PushToastMessagesPayload } from "./pushToastMessages";

export type ToastMessagesState = {
    toastMessages:Array<ToastMessage>
}

const initialState:ToastMessagesState={
    toastMessages:[]
};

enum Actions {
    "PUSH_TOAST_MESSAGES"="PUSH_TOAST_MESSAGES"
}

export class ToastMessagesReducer extends ReducerCollection<ToastMessagesState>{

    protected createActions(){
        return{
            [Actions.PUSH_TOAST_MESSAGES]:pushToastMessage
        }
    }

    protected initState(){
        return initialState;
    }
}

export const callPushToastMessages = (payload:PushToastMessagesPayload)=>{
    return callAction(Actions.PUSH_TOAST_MESSAGES,payload);
}