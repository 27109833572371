export default{
    "COLUMN_USERNAME":"Username",
    "COLUMN_ADMIN":"Admin",
    "COLUMN_ADMIN_YES":"Yes",
    "COLUMN_ADMIN_NO":"No",
    "COLUMN_SLUG":"Link",
    "COLUMN_DISPLAY":"Display",

    "EDIT_PROFILE":"Edit profile",
    "CHANGE_PASSWORD":"Change password",

    "PASSWORD_CHANGE_MODAL_HEADER":"Change password",
    "PASSWORD_CHANGE_MODAL_DESC":"",
    "PASSWORD_CHANGE_MODAL_INPUT_PASSWORD":"Previous password",
    "PASSWORD_CHANGE_MODAL_INPUT_NEWPASSWORD":"New password",
    "PASSWORD_CHANGE_MODAL_INPUT_NEWPASSWORD2":"Repeat new password",

    "PASSWORD_CHANGE_MODAL_ACCEPTED":"Password has been changed",
    "PASSWORD_CHANGE_MODAL_PASSWORDLENGTH_ERROR":"Password should be 8 characters minimum",
    "PASSWORD_CHANGE_MODAL_MATCH_ERROR":"Passwords are not the same",
    "PASSWORD_CHANGE_MODAL_UNKNOWN_ERROR":"Unknown error",
    "PASSWORD_CHANGE_MODAL_WRONGPASSWORD_ERROR":"Wrong previous password",
    
    "PROFILE_UPDATE_MODAL_HEADER":"Update profile",
    "PROFILE_UPDATE_MODAL_DESC":"",
    "PROFILE_UPDATE_MODAL_INPUT_DISPLAY":"Display",

}