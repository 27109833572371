import { PointRules } from "./point-rules.model";
import { Scoreboard } from "./scoreboard.model";

export enum TournamentType{
    "OPEN"="open"
}

export type Tournament = {
    _id:number,
    name:string,
    slug:string,
    type:TournamentType,
    moderators:Array<number>,
    participants:Array<number>,
    invited:Array<number>,
    finished:boolean,
    time_start?:number,
    time_end?:number,
    scoreboard?:Scoreboard,
    point_rules:PointRules

}