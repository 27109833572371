import StandardModal, { StandardModalProps } from "../modal/StandardModal";
import { useTranslate } from "react-translate";
import { useState } from "react";
import { UserSevice } from "../../modules/user/userService";

const ProfileUpdateModal: React.FC<StandardModalProps> = (props) => {
  const { onClose, show } = props;
  const t = useTranslate("profilPage");

  const [display, setDisplay] = useState<string>("");

  const profileUpdate = () => {
    const userService = UserSevice.get();
    userService.userUpdate(display);
  };

  return (
    <StandardModal
      show={show}
      onExit={onClose}
      onSave={() => {
        profileUpdate()
        onClose();
      }}
      saveEnabled={true}
      title={t("PROFILE_UPDATE_MODAL_HEADER")}
    >
      <p>{t("PROFILE_UPDATE_MODAL_DESC")}</p>

      <div className="input-group mb-3">
        <span
          style={{ width: "40%" }}
          className="input-group-text"
          id="basic-addon3"
        >
          {t("PROFILE_UPDATE_MODAL_INPUT_DISPLAY")}
        </span>
        <input
          value={display}
          onChange={(e) => setDisplay(e.target.value)}
          type="text"
          className="form-control"
          id="basic-url"
          aria-describedby="basic-addon3"
        />
      </div>
    </StandardModal>
  );
};

export default ProfileUpdateModal;
