import { Tournament } from "../../modules/tournaments/model/tournament.model";
import { useTranslate } from "react-translate";
import { useTypedSelector } from "../../store/root";
import { useEffect, useMemo, useState } from "react";
import { useTable } from "react-table";

import deleteIcon from "../../assets/admin-panel/icons/trash-fill.svg";
import addIcon from "../../assets/admin-panel/icons/plus-square-fill.svg";
import editIcon from "../../assets/admin-panel/icons/pencil-fill.svg";
import resultIcon from "../../assets/admin-panel/icons/check-square-fill.svg";
import MatchCreateModal from "./MatchCreateModal";
import { timestampToDisplay } from "../../utils/time";
import MatchAddScoreModal, {
  MatchAddScoreModalData,
} from "./MatchAddScoreModal";
import MatchUpdateModal, { MatchUpdateModalData } from "./MatchUpdateModal";
import MatchDeleteModal, { MatchDeleteModalData } from "./MatchDeleteModal";
import moment from 'moment';

const getTime = ()=>{
  return moment().unix();
}

const MatchesTable: React.FC<{ tournament: Tournament | undefined }> = (
  props
) => {
  const { tournament } = props;
  const t = useTranslate("matchesTable");

  const matches = useTypedSelector((state) => state.tournaments.matches);
  const tournamentMatches = useMemo(
    () => matches.filter((match) => +match.tournament === tournament?._id).sort((match1,match2)=>match1.time_start>match2.time_start ? 1 : -1),
    [matches, tournament]
  );



  const [time,setTime] = useState<number>(getTime());
  useEffect(()=>{
    const interval = setInterval(()=>{
      setTime(getTime());
    },60000);
    return clearInterval(interval);
  },[])

  const [showMatchCreateModal, setShowMatchCreateModal] = useState<boolean>(
    false
  );

  const [showMatchAddScoreModal, setShowMatchAddScoreModal] = useState<boolean>(
    false
  );
  const [matchAddScoreModalData, setMatchAddScoreModalData] = useState<
    MatchAddScoreModalData | undefined
  >(undefined);

  const [showMatchUpdateModal, setShowMatchUpdateModal] = useState<boolean>(
    false
  );
  const [matchUpdateModalData, setMatchUpdateModalData] = useState<
    MatchUpdateModalData | undefined
  >(undefined);

  const [showMatchDeleteModal, setShowMatchDeleteModal] = useState<boolean>(
    false
  );
  const [matchDeleteModalData, setMatchDeleteModalData] = useState<
    MatchDeleteModalData | undefined
  >(undefined);

  const columns = useMemo(
    () => [
      {
        Header: t("COLUMN_timestart"),
        accessor: "time_start",
        Cell: (obj: any) => String(`${timestampToDisplay(obj.value)}`),
      },
      {
        Header: t("COLUMN_team1"),
        accessor: "team1",
      },
      {
        Header: t("COLUMN_team2"),
        accessor: "team2",
      },
      {
        Header: t("COLUMN_score1"),
        accessor: "score1",
      },
      {
        Header: t("COLUMN_score2"),
        accessor: "score2",
      },
    ],
    []
  );

  const data = useMemo(
    () =>
      tournamentMatches.map((match) => ({
        id: match._id,
        time_start: match.time_start,
        team1: match.team1,
        team2: match.team2,
        score1: match.score1,
        score2: match.score2,
      })),
    [tournamentMatches]
  );

  const tableInstance = useTable({
    columns: columns as any,
    data,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  if (!tournament) {
    return null;
  }

  return (
    <div>
      <h3>{t("TABLE_HEADER")}</h3>
      <p>{t("TABLE_DESC")}</p>

      <table className="table" {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()} scope="col">
                      {
                        // Render the header
                        column.render("Header")
                      }

                      {/* Add a sort direction indicator */}
                    </th>
                  ))
                }
                <th></th>
                <th></th>
                <th></th>
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render("Cell")
                          }
                        </td>
                      );
                    })
                  }
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowMatchUpdateModal(true);
                      setMatchUpdateModalData({
                        matchId: row.original.id,
                        matchTeam1: row.original.team1 || "",
                        matchTeam2: row.original.team2 || "",
                        matchTimestart: row.original.time_start,
                      });
                    }}
                  >
                    <img src={editIcon} alt="edit" />
                  </td>
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowMatchDeleteModal(true);
                      setMatchDeleteModalData({
                        matchId: row.original.id,
                        matchTeam1: row.original.team1 || "",
                        matchTeam2: row.original.team2 || "",
                      });
                    }}
                  >
                    <img src={deleteIcon} alt="delete" />
                  </td>
                  {row.original.time_start>=time ? "" :
                  <td
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setShowMatchAddScoreModal(true);
                      setMatchAddScoreModalData({
                        matchId: row.original.id,
                        matchTeam1: row.original.team1 || "",
                        matchTeam2: row.original.team2 || "",
                      });
                    }}
                  >
                    <img src={resultIcon} alt="result" />
                  </td>
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
      <div style={{ margin: 8, marginRight: 30, textAlign: "right" }}>
        <img
          onClick={() => {
            setShowMatchCreateModal(true);
          }}
          style={{ cursor: "pointer", height: 24, width: 24 }}
          src={addIcon}
          alt="add"
        />
      </div>

      <MatchCreateModal
        onClose={() => setShowMatchCreateModal(false)}
        show={showMatchCreateModal}
        tournament={tournament}
      />

      <MatchAddScoreModal
        show={showMatchAddScoreModal}
        onClose={() => setShowMatchAddScoreModal(false)}
        tournament={tournament}
        data={matchAddScoreModalData}
      ></MatchAddScoreModal>

      <MatchUpdateModal
        show={showMatchUpdateModal}
        onClose={() => setShowMatchUpdateModal(false)}
        tournament={tournament}
        data={matchUpdateModalData}
      />

      <MatchDeleteModal
        show={showMatchDeleteModal}
        onClose={()=>setShowMatchDeleteModal(false)}
        tournament={tournament}
        data={matchDeleteModalData}
      />
    </div>
  );
};

export default MatchesTable;
