import { Tournament } from "../../modules/tournaments/model/tournament.model";
import StandardModal, { StandardModalProps } from "../modal/StandardModal";
import { useTranslate } from "react-translate";
import { useState } from "react";
import { ModerationsService } from "../../modules/moderations/ModerationsService";

export type MatchAddScoreModalData={
    matchId:number,
    matchTeam1:string,
    matchTeam2:string,
}

const MatchAddScoreModal: React.FC<StandardModalProps&{
    tournament:Tournament,
    data:MatchAddScoreModalData|undefined
}>=(props)=>{

    const {data,onClose,show,tournament} = props;
    const t = useTranslate("matchesTable");

    const [score1,setScore1] = useState<number>(0);
    const [score2,setScore2] = useState<number>(0);

    if(!data){
        return null;
    }

    const matchAddScore = ()=>{
        const moderationService = ModerationsService.get();
        moderationService.matchUpdate(tournament._id,data.matchId,{score1,score2})
    }

    return (
        <StandardModal
        show={show}
        onExit={onClose}
        onSave={()=>{
            matchAddScore()
            onClose()
        }}
        saveEnabled={true}
        title={t("ADD_MATCH_SCORE_MODAL_HEADER")}
        >
        <p>{t("ADD_MATCH_SCORE_MODAL_DESC",{team1:data.matchTeam1,team2:data.matchTeam2})}</p>

        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {data.matchTeam1}
          </span>
          <input
            value={score1}
            onChange={(e)=>setScore1(+e.target.value)}
            type="number"
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {data.matchTeam2}
          </span>
          <input
            value={score2}
            onChange={(e)=>setScore2(+e.target.value)}
            type="number"
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>

        </StandardModal>
    );
}

export default MatchAddScoreModal;