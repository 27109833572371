import { useTypedSelector } from "../../store/root";
import {useTranslate} from 'react-translate';

const TournamentSwitch: React.FC<{selectedTournamentId:number|null,onSelectedTournamentChange:(tournamentId:number)=>void}> = (props)=>{

    const {onSelectedTournamentChange,selectedTournamentId} = props;
    const moderatedTournaments = useTypedSelector(state=>state.moderations.moderatedTournaments)
    const t = useTranslate("modPanel");

    return (<div>
                <select className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                value={`${selectedTournamentId}`} 
                onChange={(e)=>onSelectedTournamentChange(+e.target.value)}
                >
        <option hidden value="">{t("SELECT_TOURNAMENT_DESC")}</option>
        {
            moderatedTournaments.map(tournament=>(
            <option key={tournament._id} value={tournament._id}>{tournament.name}</option>

            ))
        }
        </select>
    </div>);
}

export default TournamentSwitch;