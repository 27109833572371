import { useTypedSelector } from "../../store/root";
import ModerationsTable from "./ModerationsTable";
import TournamentTable from "./TournamentTable";
import UsersTable from "./UsersTable";

const AdminPanel:React.FC=()=>{

    const {admin,adminDataLoaded}:{admin:boolean,adminDataLoaded:boolean} = useTypedSelector(state=>state.admin) as any;

    if(!admin||!adminDataLoaded){
        return null
    }
    

    return (
        <div>
            <UsersTable/>
            <TournamentTable/>
            <ModerationsTable/>
        </div>
    );
}

export default AdminPanel;