import { useMemo, useState } from "react";
import { LoadedAdminState } from "../../modules/admin/store";
import { useTypedSelector } from "../../store/root";
import { useTranslate } from "react-translate";
import { useTable } from "react-table";

import deleteIcon from "../../assets/admin-panel/icons/trash-fill.svg";
import addIcon from "../../assets/admin-panel/icons/plus-square-fill.svg";
import AddModerationModal from "./AddModerationModal";
import DeleteModerationModal, { DeleteModerationModalData } from "./DeleteModerationModal";

const ModerationsTable: React.FC = () => {
  const { tournaments, users } = useTypedSelector(
    (state) => (state.admin as LoadedAdminState).adminData
  );

  const t = useTranslate("moderationsTable");

  const [showAddModerationModal, setShowAddModerationModal] = useState<boolean>(
    false
  );

  const [showDeleteModerationModal,setShowDeleteModerationModal] = useState<boolean>(false);
  const [deleteModerationModalData,setDeleteModerationModalData] = useState<DeleteModerationModalData|null>(null);

  const columns = useMemo(
    () => [
      {
        Header: t("USER_NAME"),
        accessor: "userName",
      },
      {
        Header: t("USER_SLUG"),
        accessor: "userSlug",
      },
      {
        Header: t("TOURNAMENT_NAME"),
        accessor: "tournamentName",
      },
      {
        Header: t("TOURNAMENT_SLUG"),
        accessor: "tournamentSlug",
      },
    ],
    [t]
  );

  const data = useMemo(
    () =>
      tournaments.reduce((arr: Array<any>, tournament): Array<any> => {
        const { name: tournamentName, slug: tournamentSlug, _id: tournamentId } = tournament;

        arr.push(
          ...tournament.moderators.map((moderatorId) => {
            const moderator = users.find((user) => user._id === moderatorId)!;
            const { username: userName, slug: userSlug, _id: userId } = moderator;
            return { tournamentName, tournamentSlug, userName, userSlug,tournamentId,userId };
          })
        );

        return arr;
      }, []),
    [tournaments, users]
  );

  const tableInstance = useTable({
    columns: columns as any,
    data,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div>
      <h3>{t("TABLE_HEADER")}</h3>
      <p>{t("TABLE_DESC")}</p>

      <table className="table" {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()} scope="col">
                      {
                        // Render the header
                        column.render("Header")
                      }

                      {/* Add a sort direction indicator */}
                    </th>
                  ))
                }
                <th></th>
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render("Cell")
                          }
                        </td>
                      );
                    })
                  }
                  <td style={{cursor:"pointer"}} onClick={()=>{
                    setDeleteModerationModalData({tournamentId:row.original.tournamentId,tournamentName:row.original.tournamentName,userId:row.original.userId,userName:row.original.userName} as any)
                    setShowDeleteModerationModal(true);
                  }}>
                    <img src={deleteIcon} alt="edit" />
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
      <div style={{ margin: 8, marginRight: 30, textAlign: "right" }}>
        <img
          onClick={() => setShowAddModerationModal(true)}
          style={{ cursor: "pointer", height: 24, width: 24 }}
          src={addIcon}
          alt="add"
        />
      </div>

      <AddModerationModal
        show={showAddModerationModal}
        onClose={() => setShowAddModerationModal(false)}
      />

      <DeleteModerationModal
      show={showDeleteModerationModal}
      onClose={()=>setShowDeleteModerationModal(false)}
      data={deleteModerationModalData}
      />
    </div>
  );
};

export default ModerationsTable;
