export default {
    "TABLE_HEADER":"Tabela meczów",
    "TABLE_DESC":"Wszystkie mecze w turnieju",
    "COLUMN_timestart":"Data rozpoczęcia",
    "COLUMN_team1":"Drużyna 1",
    "COLUMN_team2":"Drużyna 2",
    "COLUMN_score1":"Wynik 1",
    "COLUMN_score2":"Wynik 2",

    "CREATE_MATCH_MODAL_HEADER":"Dodaj mecz",
    "CREATE_MATCH_MODAL_DESC":"Dodaj nowy mecz",
    "CREATE_MATCH_MODAL_INPUT_TEAM1":"Drużyna 1",
    "CREATE_MATCH_MODAL_INPUT_TEAM2":"Drużyna 2",
    "CREATE_MATCH_MODAL_INPUT_TIMESTART":"Data rozpoczęcia",

    "MATCH_DELETE_MODAL_HEADER":"Usuń mecz",
    "MATCH_DELETE_MODAL_DESC":"Usuń mecz {{team1}}-{{team2}}",

    "UPDATE_MATCH_MODAL_HEADER":"Edytuj mecz",
    "UPDATE_MATCH_MODAL_DESC":"Edytuj mecz {{team1}}-{{team2}}.",

    "ADD_MATCH_SCORE_MODAL_HEADER":"Dodaj wynik",
    "ADD_MATCH_SCORE_MODAL_DESC":"Dodaj wynik meczu {{team1}}-{{team2}}"
}