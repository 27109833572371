import { useEffect, useMemo } from "react";
import {Modal} from 'bootstrap';
import {v4 as uuidv4} from 'uuid';
import {useTranslate} from 'react-translate';

export type StandardModalProps = {
  show: boolean;
  onClose: () => void;
};

export const StandardModal:React.FC<{show:boolean,saveEnabled:boolean,onExit:()=>void,onSave:()=>void,title?:string,save?:string}>=(props)=>{

    const {onExit,onSave,saveEnabled,show,children,title="",save} = props;

    const modalId = useMemo(()=>`modal-${uuidv4()}`,[])
    const t = useTranslate("modal");

    useEffect(()=>{
        const modalEl = document.getElementById(modalId)!
        new Modal(modalEl)
        modalEl.addEventListener('hidden.bs.modal', function (event) {
            onExit();
          })
      },[])

    useEffect(()=>{

        const modalEl = document.getElementById(modalId)!;
        var modal = Modal.getInstance(modalEl) 
  
        if(!modal){
          return;
        }
        if(show){
          modal.show()
        }else{
          
          modal.hide();
        }
      },[show])

      
    return (
    <div className="modal fade" id={modalId} tabIndex={-1} aria-labelledby="usersTableModal" aria-hidden="true">
    <div className="modal-dialog">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">{title}</h5>
          <button type="button" className="btn-close" onClick={()=>{onExit()}} aria-label="Close"></button>
        </div>
        <div className="modal-body">
          {children}
        </div>
        <div className="modal-footer">
          <button type="button" className="btn btn-secondary" onClick={()=>{onExit()}} >{t("CLOSE")}</button>
    <button type="button" className="btn btn-primary" disabled={!saveEnabled} onClick={()=>{if(saveEnabled) onSave()}}>{save ? save : t("SAVE")}</button>
        </div>
      </div>
    </div>
  </div>
  )
}

export default StandardModal;