import modal from './modal';
import usersTable from './users-table';
import tournamentsTable from './tournaments-table';
import modPanel from './mod-panel';
import moderationsTable from './moderations-table';
import participantsTable from './participants-table'
import matchesTable from './matches-table';
import navbar from './navbar';
import betsPage from './bets-page';
import profilPage from './profil-page';
import scoreboard from './scoreboard';
import matchesPage from './matches-page'

export default {
    locale: "en",
    languageTag:{
        languageTag:"EN"
    },
    modal,
    usersTable,
    tournamentsTable,
    moderationsTable,
    modPanel,
    participantsTable,
    matchesTable,
    navbar,
    betsPage,
    profilPage,
    scoreboard,
    matchesPage
};