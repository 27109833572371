import { ToastMessagesState } from ".";
import { ToastMessage } from "../models/toast-message.model";

export type PushToastMessagesPayload = {
  toastMessages: Array<ToastMessage>;
};

export const pushToastMessage = (
  state: ToastMessagesState,
  payload: PushToastMessagesPayload
): ToastMessagesState => {
  const { toastMessages: prevToastMessages } = state;
  const { toastMessages: newToastMessages } = payload;

  const toastMessagesToAdd = newToastMessages.filter((toastMessage) => 
    !prevToastMessages.find(
      (prevToastMessage) => prevToastMessage.id === toastMessage.id
    )
  );

  return {
    toastMessages:[...prevToastMessages,...toastMessagesToAdd]
  };
};
