export default {
    "TABLE_HEADER":"Moderacje",
    "TABLE_DESC":"Wszystkie moderacje w typerze.",

    "USER_NAME":"Nazwa użytkownika",
    "USER_SLUG":"Link użytkownika",
    "TOURNAMENT_NAME":"Nazwa turnieju",
    "TOURNAMENT_SLUG":"Link turnieju",

    "ADD_MODERATION_MODAL_HEADER":"Dodaj nową moderację",
    "ADD_MODERATION_MODAL_DESC":"Dodaj nową moderację.",
    "ADD_MODERATION_MODAL_INPUT_USERSLUG":"Link użytkownika",
    "ADD_MODERATION_MODAL_INPUT_TOURNAMENTSLUG":"Link turnieju",

    "DELETE_MODERATION_MODAL_HEADER":"Usuń moderację",
    "DELETE_MODERATION_MODAL_DESC":"Usuń moderację {{username}} z turnieju {{tournamentName}}.",
}