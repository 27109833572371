import { isArray } from "lodash";
import { TournamentsState } from ".";
import { User } from "../../auth/model/user-data.model";
import { Bet } from "../model/bet.model";
import { Match } from "../model/match.model";
import { Tournament } from "../model/tournament.model";

export type SetTournamentsDataPayload = {
    tournamentsParticipate?:Array<Tournament>,
    tournaments?:Array<Tournament>,
    matches?:Array<Match>,
    bets?:Array<Bet>,
    users?:Array<User>
}

export const setTournamentsData = (state:TournamentsState,payload:SetTournamentsDataPayload):TournamentsState=>{
    const {bets:prevBets,tournaments:prevTournaments,matches:prevMatches,tournamentsParticipate:prevTournamentsParticipate,users:prevUsers} = state;
    const {tournaments,bets,matches,tournamentsParticipate,users} = payload;

    return{
        tournaments:isArray(tournaments) ? tournaments : prevTournaments,
        bets:isArray(bets) ? bets : prevBets,
        matches:isArray(matches) ? matches : prevMatches,
        tournamentsParticipate:isArray(tournamentsParticipate) ? tournamentsParticipate : prevTournamentsParticipate,
        users:isArray(users) ? users : prevUsers
    }
}