import { Team } from '../models/team.model'
import {TeamsState} from '.'
import * as _ from 'lodash'

export type DeleteTeamPayload = {
    team:Team
}

export function deleteTeam(state: TeamsState,payload:DeleteTeamPayload): TeamsState{

    let index = state.teams.findIndex(team=>team===payload.team)
    if(index===-1) return state;

    const teams = _.cloneDeep(state.teams);
    teams.splice(index,1)
    return {teams}
}