import { useEffect, useState } from "react"
import Navbar from "./Navbar"
import {useSpring,animated} from 'react-spring';

const FixedNavbar: React.FC = ()=>{

    const [show,setShow] = useState<boolean>(false);
    const topValue = useSpring({to:{top:show ? 0 : -60}})

    useEffect(()=>{
        let prevScrollVal = 0;
        const onScroll = ()=>{
            const scrollVal = window.scrollY;
            if(scrollVal===prevScrollVal){
                return;
            }
            if(scrollVal>prevScrollVal){
                //scrolling down
                setShow(false);
            }else{
                setShow(true);
            }
            prevScrollVal=scrollVal;
        }


        window.addEventListener("scroll",onScroll);
        return ()=>window.removeEventListener("scroll",onScroll);
    },[])

    return null

    return(

        <animated.div style={{position:"fixed",...(topValue as any),width:"100%",zIndex:100}}>
            <Navbar/>
        </animated.div>
    )
}

export default FixedNavbar