import { TypedUseSelectorHook, useSelector } from 'react-redux'
import { combineReducers } from 'redux'
import { AdminReducer, AdminState } from '../modules/admin/store'
import { AuthReducer, AuthState } from '../modules/auth/store'
import { ModerationsReducer, ModerationsState } from '../modules/moderations/store'
import {TeamsReducer, TeamsState} from '../modules/teams/store'
import { ToastMessagesReducer, ToastMessagesState } from '../modules/toast-messages/store'
import { TournamentsReducer, TournamentsState } from '../modules/tournaments/store'
import { TranslateState, TranslateReducer } from '../modules/translate/store'
import { UserReducer, UserState } from '../modules/user/store'

const teams = new TeamsReducer()
const auth = new AuthReducer();
const admin = new AdminReducer();
const translate = new TranslateReducer();
const tournaments = new TournamentsReducer();
const moderations = new ModerationsReducer();
const user = new UserReducer();
const toastMessages = new ToastMessagesReducer();

export interface ReducersState {
    teams:TeamsState,
    auth:AuthState,
    admin:AdminState,
    translate:TranslateState,
    tournaments:TournamentsState,
    moderations:ModerationsState,
    user:UserState,
    toastMessages:ToastMessagesState
}

export const getReducers = () => combineReducers({
    teams: teams.changeState.bind(teams),
    auth: auth.changeState.bind(auth),
    admin: admin.changeState.bind(admin),
    translate: translate.changeState.bind(translate),
    tournaments: tournaments.changeState.bind(tournaments),
    moderations: moderations.changeState.bind(moderations),
    user: user.changeState.bind(user),
    toastMessages: toastMessages.changeState.bind(toastMessages)
})

export const useTypedSelector: TypedUseSelectorHook<ReducersState> = useSelector

