import { Store } from "redux";
import { GlobalMessages } from "../../config/global-messsages";
import { ToastMessage } from "./models/toast-message.model";
import { callPushToastMessages } from "./store";
import { addReadedToastMessage, getReadedToastMessages } from "./toast-messages.idb";

export class ToastMessagesService{
    private constructor(private store: Store) {
        this.pushGlobalToastMessages();
    }
  
    private static self: ToastMessagesService;
    static init(store: Store) {
      this.self = new this(store);
      return this.get();

    }
    
    static get() {
        if (!this.self) {
          throw Error("Not initialized yet.");
        }
        return this.self;
      }

    async pushToastMessages(toastMessages:Array<ToastMessage>){
        const toastMessagesFiltered = await this.filterPushedToastMessages(toastMessages);
        this.dipsatchPushToastMessages(toastMessagesFiltered)
    }

    async markMessageAsReaded(toastMessageID:string){
        addReadedToastMessage(toastMessageID);
        return;
    }

    private async pushGlobalToastMessages(){
        const globalMessagesFiltered = await this.filterPushedToastMessages(GlobalMessages);
        this.dipsatchPushToastMessages(globalMessagesFiltered);
    }

    private async filterPushedToastMessages(toastMessages:Array<ToastMessage>):Promise<Array<ToastMessage>>{
        const readedMessagesId = await getReadedToastMessages();
        return toastMessages.filter(message=>!readedMessagesId.find(readedMessageId=>readedMessageId===message.id));
    }

    private dipsatchPushToastMessages(toastMessages:Array<ToastMessage>){
        this.store.dispatch(callPushToastMessages({toastMessages}))
    }

}