import { useMemo, useState } from "react";
import { useTypedSelector } from "../../store/root";
import MatchesTable from "./MatchesTable";
import ParticipantsTable from "./ParticipantsTable";
import TournamentDesc from "./TournamentDesc";
import TournamentSwitch from "./TournamentSwitch";
import TournamentUpdateModal from "./TournamentUpdateModal";
import editIcon from "../../assets/admin-panel/icons/pencil-fill.svg";
import { useTranslate } from "react-translate";

const ModPanel: React.FC = ()=>{

    const moderatedTournaments = useTypedSelector(state=>state.moderations.moderatedTournaments);
    let [selectedTournamentId,setSelectedTournamentId] = useState<number|null>(null);
    const selectedTournament = useMemo(()=>moderatedTournaments.find(moderatedTournament=>moderatedTournament._id===selectedTournamentId),[moderatedTournaments,selectedTournamentId]);

    const t = useTranslate("modPanel");

    const [showTournamentUpdateModal,setShowTournamentUpdateModal] = useState<boolean>(false);

    if(moderatedTournaments.length===0){
        return null;
    }


    return (
        <>
        <TournamentSwitch
        onSelectedTournamentChange={setSelectedTournamentId}
        selectedTournamentId={selectedTournamentId}
        />
        {!selectedTournament ? "" : 
        <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
        <div style={{textAlign:"center",cursor:"pointer"}} onClick={()=>{setShowTournamentUpdateModal(true)}}>
                    {t("EDIT_TOURNAMENT")}<img style={{marginLeft:5}} src={editIcon} alt="edit" /></div>
                    </div>
        }
        <TournamentDesc
        tournament={selectedTournament}
        />
        <ParticipantsTable
        tournament={selectedTournament}
        />
        <MatchesTable
        tournament={selectedTournament}
        />
        <TournamentUpdateModal
        show={showTournamentUpdateModal}
        onClose={()=>setShowTournamentUpdateModal(false)}
        tournament={selectedTournament}
        />
        </>
        );
}

export default ModPanel;