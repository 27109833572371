export default {
    "TABLE_HEADER":"All Users",
    "TABLE_DESC":"All registered users.",
    "USERNAME":"Username",
    "DISPLAY":"Display",
    "SLUG":"Link",
    "ADMIN":"Admin",
    "ADMIN_YES":"Yes",
    "ADMIN_NO":"No",

    "PROMOTE_MODAL_HEADER":"Promote user",
    "PROMOTE_MODAL_DESC":"Promote user {{username}} to admin.",
    
    "PROMOTE_MODAL_HEADER_DEGRADE":"Degrade user",
    "PROMOTE_MODAL_DESC_DEGRADE":"Degrade user {{username}} to normal user.",

    "CREATE_USER_MODAL_HEADER":"Create user",
    "CREATE_USER_MODAL_DESC":"Create new user.",
    "CREATE_USER_MODAL_INPUT_USERNAME":"Username",
    "CREATE_USER_MODAL_INPUT_PASSWORD":"Password",
    "CREATE_USER_MODAL_INPUT_DISPLAY":"Display",

    "PASSWORD_CHANGE_MODAL_HEADER":"Set new password",
    "PASSWORD_CHANGE_MODAL_DESC":"Set new password for user {{username}}.",
    "PASSWORD_CHANGE_MODAL_INPUT_PASSWORD":"New password",
}