export default {
    "TABLE_HEADER":"All tournaments",
    "TABLE_DESC":"All tournaments.",

    "NAME":"Name",
    "SLUG":"Link",
    "TIME_START":"Start time",
    "TIME_END":"End time",
    "MODERATORS":"Moderators number",
    "INVITED":"Invited number",
    "PARTICIPANTS":"Participants number",

    "CREATE_TOURNAMENT_MODAL_HEADER":"Create tournament",
    "CREATE_TOURNAMENT_MODAL_DESC":"Create new tournament",
    "CREATE_TOURNAMENT_MODAL_INPUT_NAME":"Tournament name",
    "CREATE_TOURNAMENT_MODAL_INPUT_POINTSFORWINNER":"Points for winner",
    "CREATE_TOURNAMENT_MODAL_INPUT_POINTSFORSCORE":"Points for score",

    "DELETE_TOURNAMENT_MODAL_HEADER":"Delete tournament",
    "DELETE_TOURNAMENT_MODAL_DESC":"Delete tournament {{name}}",
}