import FixedNavbar from "./FixedNavbar";
import Navbar from "./Navbar";
import Page from "./Page";
import PageSwitch from "./PageSwitch";

const Skeleton: React.FC = ()=>{

    return(
        <>
        <FixedNavbar/>
        <Navbar/>
        <Page>
            <PageSwitch/>
        </Page>
        </>
    )

}

export default Skeleton;