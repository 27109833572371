import { callAction, ReducerCollection } from "../../../store/core";
import { Bet } from "../../tournaments/model/bet.model";
import { setUserBets, SetUserBetsPayload } from "./setUserBets";

export type UserState = {
    userBets:Array<Bet>,
}

const initialState:UserState = {
    userBets:[]
}

enum Actions{
    "SET_USER_BETS"="SET_USER_BETS",
}

export class UserReducer extends ReducerCollection<UserState>{

    protected createActions(){
        return{
            [Actions.SET_USER_BETS]:setUserBets
        }
    }

    protected initState(){
        return initialState;
    }
}

export const callSetUserBets = (payload:SetUserBetsPayload)=>{
    return callAction(Actions.SET_USER_BETS,payload);
}