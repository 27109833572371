import { useState } from "react";
import { useTranslate } from "react-translate";
import { AdminService } from "../../modules/admin/admin.service";
import { TournamentType } from "../../modules/tournaments/model/tournament.model";
import StandardModal, { StandardModalProps } from "../modal/StandardModal";

const CreateTournamentModal: React.FC<StandardModalProps> = (props) =>{
    const {show,onClose} = props;
    const t = useTranslate("tournamentsTable");
    
    const [name,setName] = useState<string>("")
    const [pointForScore,setPointsForScore] = useState<number>(0);
    const [pointForWinner,setPointsForWinner] = useState<number>(0);

    const createTournament = (name:string,pointsForScore:number,pointsForWinner:number)=>{
        const adminService = AdminService.get();
        adminService.tournamentCreate(name,TournamentType.OPEN,false,{score:pointsForScore,winner:pointsForWinner})
    }

    return (
        <StandardModal
        show={show}
        onExit={onClose}
        onSave={()=>{
            onClose();
            createTournament(name,pointForScore,pointForWinner)
        }}
        saveEnabled={true}
        title={t("CREATE_TOURNAMENT_MODAL_HEADER")}
        >
        <p>{t("CREATE_TOURNAMENT_MODAL_DESC")}</p>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("CREATE_TOURNAMENT_MODAL_INPUT_NAME")}
          </span>
          <input
            value={name}
            onChange={(e)=>setName(e.target.value)}
            type="text"
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("CREATE_TOURNAMENT_MODAL_INPUT_POINTSFORWINNER")}
          </span>
          <input
            value={pointForWinner}
            onChange={(e)=>setPointsForWinner(+e.target.value)}
            type="number"
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("CREATE_TOURNAMENT_MODAL_INPUT_POINTSFORSCORE")}
          </span>
          <input
            value={pointForScore}
            onChange={(e)=>setPointsForScore(+e.target.value)}
            type="number"
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>


        </StandardModal>
    );
}

export default CreateTournamentModal;