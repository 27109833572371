import { Match } from "../modules/tournaments/model/match.model";
import { AvailableLanguageTags } from "../modules/translate/models/available-language-tags";

const translations = {
    "EN":{
        "Anglia":"England",
        "Ukraina":"Ukraine",
        "Niemcy":"Germany",
        "Szwajcaria":"Switzerland",
        "Chorwacja":"Croatia",
        "Hiszpania":"Spain",
        "Polska":"Poland",
        "Francja":"France",
        "Belgia":"Belgium",
        "Włochy":"Italy",
        "Czechy":"Czech Republic",
        "Portugalia":"Portugal",
        "Holandia":"The Netherlands",
        "Dania":"Denmark",
        "Walia":"Wales",
        "Szwecja":"Sweden",
        "Austria":"Austria",
        "Turcja":"Turkey",
        "Rosja":"Russia",
        "Finlandia":"Finland",
        "Węgry":"Hungary",
        "Słowacja":"Slovakia",
        "Szkocja":"Scotland",
        "Macedonia Północna":"North Macedonia",
    }    
}

export const translateMatches = (matches:Array<Match>,languageTag:AvailableLanguageTags):Array<Match>=>{
    if(!(languageTag in translations)){
        return matches;
    }

    const translation = (translations as any)[languageTag];

    return matches.map(match=>{
        const translatedTeam1 = (match.team1 || "") in translation ? translation[match.team1!] : null
        const translatedTeam2 = (match.team2 || "") in translation ? translation[match.team2!] : null
        if(translatedTeam1||translatedTeam2){
            return{
                ...match,
                team1:translatedTeam1||match.team1,
                team2:translatedTeam2||match.team2,
            }
        }
        return match;

    });
}
