import { Tournament } from "../../modules/tournaments/model/tournament.model"
import StandardModal, { StandardModalProps } from "../modal/StandardModal"
import { useTranslate } from "react-translate";
import { useEffect, useState } from "react";
import { ModerationsService } from "../../modules/moderations/ModerationsService";
import { datetimeToTimestamp, timestampToDatetime } from "../../utils/time";

export type MatchUpdateModalData = {
    matchId:number,
    matchTeam1:string,
    matchTeam2:string,
    matchTimestart:number,
}

const MatchUpdateModal: React.FC<StandardModalProps&{
    tournament:Tournament,
    data:MatchUpdateModalData|undefined
}>=(props)=>{
    const {data,onClose,show,tournament} = props;
    const t = useTranslate("matchesTable");

    const [timeStart,setTimeStart] = useState<number|undefined>();
    const [team1,setTeam1] = useState<string|undefined>(undefined)
    const [team2,setTeam2] = useState<string|undefined>(undefined)

    useEffect(()=>{
        if(!data){
            return
        };

        setTimeStart(data.matchTimestart);
        setTeam1(data.matchTeam1)
        setTeam2(data.matchTeam2)
    },[data])


    if(!data){
        return null;
    }

    const matchUpdate= ()=>{

        if(!timeStart){
            return;
        }

        const moderationService =ModerationsService.get();
        moderationService.matchUpdate(tournament._id,data.matchId,{time_start:timeStart,team1,team2})
    }


    return (
        <StandardModal
        show={show}
        onExit={onClose}
        onSave={()=>{
            matchUpdate()
            onClose()
        }}
        saveEnabled={true}
        title={t("UPDATE_MATCH_MODAL_HEADER")}
        >
            <p>{t("UPDATE_MATCH_MODAL_DESC",{team1:data.matchTeam1,team2:data.matchTeam2})}</p>
      <div className="input-group mb-3">
        <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
          {t("CREATE_MATCH_MODAL_INPUT_TEAM1")}
        </span>
        <input
          value={team1||""}
          onChange={(e)=>setTeam1(e.target.value)}
          type="text"
          className="form-control"
          id="basic-url"
          aria-describedby="basic-addon3"
        />
      </div>
      <div className="input-group mb-3">
        <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
          {t("CREATE_MATCH_MODAL_INPUT_TEAM2")}
        </span>
        <input
          value={team2||""}
          onChange={(e)=>setTeam2(e.target.value)}
          type="text"
          className="form-control"
          id="basic-url"
          aria-describedby="basic-addon3"
        />
      </div>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("CREATE_MATCH_MODAL_INPUT_TIMESTART")}
          </span>
          <input
            type="datetime-local"
            value={!timeStart ? "" : timestampToDatetime(timeStart) as any as number}
            onChange={(e)=>{setTimeStart(datetimeToTimestamp(e.target.value as any))}}
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>
        </StandardModal>
    );
}

export default MatchUpdateModal;