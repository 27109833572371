import { useEffect, useState } from "react";
import { Tournament } from "../../modules/tournaments/model/tournament.model";
import StandardModal, { StandardModalProps } from "../modal/StandardModal";
import { useTranslate } from "react-translate";
import { ModerationsService } from "../../modules/moderations/ModerationsService";
import { datetimeToTimestamp, timestampToDatetime } from "../../utils/time";

const TournamentUpdateModal: React.FC<StandardModalProps&{tournament:Tournament|undefined}>=(props)=>{

    const {onClose,show,tournament} = props;
    const t = useTranslate("modPanel");

    const [name,setName] = useState<string>("")
    const [pointForScore,setPointsForScore] = useState<number>(0);
    const [pointForWinner,setPointsForWinner] = useState<number>(0);
    const [finished,setFinished] = useState<boolean>(false);
    const [timeStart,setTimeStart] = useState<number|undefined>(undefined);
    const [timeEnd,setTimeEnd] = useState<number|undefined>(undefined);

    useEffect(()=>{
        if(!tournament){
            return;
        }
        setName(tournament.name)
        setPointsForScore(tournament.point_rules.score);
        setPointsForWinner(tournament.point_rules.winner);
        setFinished(tournament.finished);
        setTimeStart(tournament.time_start);
        setTimeEnd(tournament.time_end);
    },[tournament])

    if(!tournament){
        return null;
    }

    const editTournament = ()=>{
      const moderationsService =ModerationsService.get();
      moderationsService.tournamentUpdate(tournament._id,{name,"point_rules.score":pointForScore,"point_rules.winner":pointForWinner,finished,time_end:timeEnd,time_start:timeStart})
    }

    return(
        <StandardModal
        show={show}
        onExit={onClose}
        onSave={()=>{
            onClose();
            editTournament();
        }}
        saveEnabled={true}
        title={t("UPDATE_TOURNAMENT_MODAL_HEADER")}
        >

<p>{t("UPDATE_TOURNAMENT_MODAL_DESC",{tournamentName:tournament.name})}</p>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("TOURNAMENT_NAME")}
          </span>
          <input
            value={name}
            onChange={(e)=>setName(e.target.value)}
            type="text"
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("TOURNAMENT_POINTFORWINNER")}
          </span>
          <input
            value={pointForWinner}
            onChange={(e)=>setPointsForWinner(+e.target.value)}
            type="number"
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("TOURNAMENT_POINTFORSCORE")}
          </span>
          <input
            value={pointForScore}
            onChange={(e)=>setPointsForScore(+e.target.value)}
            type="number"
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>
        <div className="form-check">
        <input className="form-check-input" type="checkbox" onClick={()=>{setFinished(finished=>!finished)}} checked={finished} id="flexCheckChecked" />
        <label className="form-check-label">
        {t("TOURNAMENT_FINISHED")}
        </label>
        </div>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("TOURNAMENT_TIMESTART")}
          </span>
          <input
            value={!timeStart ? "" : timestampToDatetime(timeStart) as any as number}
            onChange={(e)=>{setTimeStart(datetimeToTimestamp(e.target.value as any))}}
            type="datetime-local"
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("TOURNAMENT_TIMEEND")}
          </span>
          <input
            type="datetime-local"
            value={!timeEnd ? "" : timestampToDatetime(timeEnd) as any as number}
            onChange={(e)=>{setTimeEnd(datetimeToTimestamp(e.target.value as any))}}
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>
        </StandardModal>
    );
}

export default TournamentUpdateModal;