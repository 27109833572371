import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation } from "react-router";
import { useTypedSelector } from "../../store/root";
import { isTournamentPage } from "../../utils/navbar";
import { sortScoreboard } from "../scoreboard-page/sortScoreboard";
import { useTranslate } from "react-translate";
import { useTable } from "react-table";
import { isUndefined } from "lodash";
import {
  BetResultType,
  checkBetColor,
  getBetColor,
} from "../../utils/betColors";
import { translateMatches } from "../../utils/translateMatches";
import { calculateStickyTopTranslate } from "../../utils/calculateStickyTranslate";

enum LABEL {
  "WINNERS" = "WINNERS",
  "SCORES" = "SCORES",
  "POINTS" = "POINTS",
}

const labels = [
  {
    type: LABEL.WINNERS,
    accessor: "winners",
  },
  {
    type: LABEL.SCORES,
    accessor: "scores",
  },
  {
    type: LABEL.POINTS,
    accessor: "points",
  },
];
const MatchCrosstable: React.FC = () => {
  const location = useLocation();
  const tournaments = useTypedSelector(
    (state) => state.tournaments.tournamentsParticipate
  );
  const tournamentPageData = useMemo(
    () => isTournamentPage(location.pathname),
    [location]
  );
  const matchesWoLang = useTypedSelector((state) => state.tournaments.matches);
  const languageTag = useTypedSelector((state) => state.translate.languageTag);
  const matches = useMemo(() => translateMatches(matchesWoLang, languageTag), [
    matchesWoLang,
  ]);
  const users = useTypedSelector((state) => state.tournaments.users);
  const t = useTranslate("matchesPage");
  const bets = useTypedSelector((state) => state.tournaments.bets);

  // if(!tournamentPageData.matched){
  //     return null;
  // }

   const tableRef = useRef<HTMLTableElement>();
  const headerRef = useRef<HTMLTableElement>();
 const [translateHeaderValue, setTranslateHeaderValue] = useState<number>(0);

  const calculateStickyTranslateVal = useCallback(() => {
    if (!tableRef.current || !headerRef.current) {
      return;
    }
    const calculatedValue = calculateStickyTopTranslate(
      tableRef.current.offsetTop,
      tableRef.current.clientHeight,
      headerRef.current.offsetTop,
      headerRef.current.clientHeight,
      window.scrollY
    );
    setTranslateHeaderValue(calculatedValue);
  }, [tableRef,headerRef]);

  useEffect(() => {calculateStickyTranslateVal()}, [calculateStickyTranslateVal]);
  useEffect(()=>{
    window.addEventListener("scroll",calculateStickyTranslateVal);
    return ()=>{window.removeEventListener("scroll",calculateStickyTranslateVal)};
  },[calculateStickyTranslateVal]) 

  const tournament = useMemo(
    () =>
      tournaments.find(
        (tournament) =>
          tournament.slug ===
          ((tournamentPageData as any).tournamentSlug as string)
      ),
    [tournaments, tournamentPageData]
  );
  const scoreboard = useMemo(
    () => sortScoreboard(tournament?.scoreboard || []),
    [tournament]
  );
  const tournamentMatches = useMemo(
    () =>
      matches
        .filter((match) => +match.tournament === tournament?._id)
        .sort((match1, match2) =>
          match1.time_start > match2.time_start ? 1 : -1
        ),
    [tournament, matches]
  );
  const participants = useMemo(
    () =>
      scoreboard
        .map((score) => users.find((user) => user._id === +score.user))
        .filter((participant) => participant !== undefined),
    [scoreboard, users]
  );

  const cellStyle = {
    padding: 0,
  };

  const getFirstColumn = useCallback(
    (rowIndex: number) => {

        const firstColumnCellStyle={
            ...cellStyle,
        }

      const matchesNumber = tournamentMatches.length;
      if (rowIndex < matchesNumber) {
        //its match
        const match = tournamentMatches[rowIndex];
        return <td
        className="bg-light" style={firstColumnCellStyle as any}>{`${match.team1}-${match.team2}`}</td>;
      } else {
        //its label
        let labelIndex = rowIndex - matchesNumber;
        if (labelIndex < labels.length) {
          return (
            <td colSpan={2} style={firstColumnCellStyle as any}>
              {t(`LABEL_${labels[labelIndex].type}`)}
            </td>
          );
        }
        return <td colSpan={2} style={firstColumnCellStyle as any}></td>;
      }
    },
    [tournamentMatches]
  );

  const getSecondColumn = useCallback(
    (rowIndex: number) => {
      const matchesNumber = tournamentMatches.length;
      if (rowIndex < matchesNumber) {
        //its match
        const match = tournamentMatches[rowIndex];
        return (
          <td
            style={{
              ...cellStyle,
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >{`${isUndefined(match.score1) ? "" : match.score1}:${
            isUndefined(match.score2) ? "" : match.score2
          }`}</td>
        );
      } else {
        //its label
        return "";
      }
    },
    [tournamentMatches]
  );

  const getUserRow = useCallback(
    (rowIndex: number, usersIndex: number) => {
      const matchesNumber = tournamentMatches.length;
      const user = participants[usersIndex];
      if (rowIndex < matchesNumber) {
        //its match
        const match = tournamentMatches[rowIndex];
        const bet = bets.find(
          (bet) => +bet.user === user?._id && +bet.match === match._id
        );

        const backgroundColor = checkBetColor(usersIndex % 2 === 0, match, bet);

        return (
          <td
            style={{
              backgroundColor,
              ...cellStyle,
              textAlign: "center",
              verticalAlign: "middle",
            }}
          >
            {bet?.hidden ? (
              "#:#"
            ) : (
              <>
                {`${isUndefined(bet?.score1) ? "" : bet!.score1}:${
                  isUndefined(bet?.score2) ? "" : bet!.score2
                }`}
              </>
            )}
          </td>
        );
      } else {
        //its label
        let labelIndex = rowIndex - matchesNumber;

        const backgroundColor = getBetColor(
          usersIndex % 2 === 0,
          BetResultType.NORMAL
        );
        if (labelIndex < labels.length) {
          return (
            <td style={{ backgroundColor, ...cellStyle, textAlign: "center" }}>
              {
                (scoreboard.find((score) => +score.user === user?._id) as any)[
                  labels[labelIndex].accessor
                ]
              }
            </td>
          );
        }
        return <td style={cellStyle}></td>;
      }
    },
    [tournamentMatches, participants, bets]
  );

  const columns = useMemo(
    () => [
      {
        id: 1,
        Header: "",
        accessor: (_originalRow: any, rowIndex: number) =>
          getFirstColumn(rowIndex),
      },
      {
        id: 2,
        Header: t("SCORE"),
        accessor: (_originalRow: any, rowIndex: number) =>
          getSecondColumn(rowIndex),
      },
      ...participants.map((user, i) => ({
        id: i + 3,
        Header: user?.display,
        accessor: (originalRow: any, rowIndex: any) => {
          return getUserRow(rowIndex, i);
        },
      })),
    ],
    [participants, getFirstColumn, getSecondColumn, getUserRow, t]
  );

  const data = useMemo(
    () => [...tournamentMatches.map((match) => ({})), {}, {}, {}],
    [tournamentMatches]
  );

  // const data = useMemo(()=>[],[])

  const tableInstance = useTable({
    columns: columns as any,
    data,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div>
      <h3>{t("TABLE_HEADER")}</h3>
      <p>{t("TABLE_DESC", { tournamentName: tournament?.name })}</p>

      <table
        ref={tableRef as any}
        style={{
          position: "relative",
        }}
        className="table"
        {...getTableProps()}
      >
        <thead
          ref={headerRef as any}
          className="bg-light"
          style={{
            textAlign: "center",
            transform:`translate(0px,${translateHeaderValue}px)`
          }}
        >
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr
                style={{}}
                {...headerGroup.getHeaderGroupProps()}
              >
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th
                      {...column.getHeaderProps()}
                      className="position-sticky"
                      style={{ textAlign: "center", top: 0, zIndex: 1 }}
                      scope="col"
                    >
                      {
                        // Render the header
                        column.render("Header")
                      }

                      {/* Add a sort direction indicator */}
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row, i) => {
              // Prepare the row for display
              prepareRow(row);

              return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <>
                          {
                            // Render the cell contents
                            cell.render("Cell")
                          }
                        </>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default MatchCrosstable;
