import { isUndefined } from "lodash"
import { Bet } from "../modules/tournaments/model/bet.model"
import { Match } from "../modules/tournaments/model/match.model"

export const scoreDark = `rgb(45,211,111)`
export const scoreLight = `rgb(97,222,147)`
export const winnerDark = `rgb(82,96,255)`
export const winnerLight = `rgb(125,135,255)`
export const normalDark = `rgb(215,216,218)`
export const normalLight = `rgb(245,246,249)`

export enum BetResultType{
    "NOT_FINISHED"="NOT_FINISHED",
    "NORMAL"="NORMAL",
    "WINNER"="WINNER",
    "SCORE"="SCORE"
}

export const getBetColor=(dark:boolean,betResultType:BetResultType):string=>{
    if(betResultType===BetResultType.NORMAL||betResultType===BetResultType.NOT_FINISHED){
        if(dark){
            return normalDark;
        }else{
            return normalLight
        }   
    }else if(betResultType===BetResultType.WINNER){
        if(dark){
            return winnerDark
        }else{
            return winnerLight
        }
    }else{
        if(dark){
            return scoreDark
        }else{
            return scoreLight
        }
    }
}

export const getBetResultType=(match:Match,bet?:Bet)=>{

    if(isUndefined(match.score1)||isUndefined(match.score2)){
        return BetResultType.NOT_FINISHED;
    }

    if(!bet||isUndefined(bet.score1)||isUndefined(bet.score2)){
        return BetResultType.NORMAL;

    }

    const winnerBet = bet.score1>bet.score2 ? 1 : bet.score1<bet.score2 ? 2 : 0;
    const winnerMatch = match.score1>match.score2 ? 1 : match.score1<match.score2 ? 2 : 0;

    if(winnerBet!==winnerMatch){
        return BetResultType.NORMAL;
    }

    if(bet.score1!==match.score1||bet.score2!==match.score2){
        return BetResultType.WINNER;
    }
    return BetResultType.SCORE;
}

export const checkBetColor = (dark:boolean,match:Match,bet?:Bet,)=>{
    const bestResultType = getBetResultType(match,bet);
    return getBetColor(dark,bestResultType);
}