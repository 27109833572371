import { useLocation } from "react-router";
import { useTypedSelector } from "../../store/root";
import { isTournamentPage } from "../../utils/navbar";
import moment from 'moment';
import FinishedMatchesTable from "./FinishedMatchesTable";
import UpcomingMatchesTable from "./UpcomingMatchesTable";
import { translateMatches } from "../../utils/translateMatches";

const BetsPage: React.FC = ()=>{

    const location = useLocation();
    const matches = useTypedSelector(state=>state.tournaments.matches);
    const tournaments = useTypedSelector(state=>state.tournaments.tournamentsParticipate);
    const tournamentPageData = isTournamentPage(location.pathname);
    const loggedIn = useTypedSelector(state=>state.auth.loggedIn);
    const languageTag = useTypedSelector(state=>state.translate.languageTag);

    if(!tournamentPageData.matched||!loggedIn){
        return null;
    }

    const tournament = tournaments.find(tournament=>tournament.slug===tournamentPageData.tournamentSlug);
    const tournamentMatches = translateMatches(matches.filter(match=>+match.tournament===tournament?._id),languageTag);

    const time = moment().unix();
    const finishedMatches = tournamentMatches.filter(match=>match.time_start<time).sort((match1,match2)=>match1.time_start>match2.time_start ? -1 : 1);
    const upcomingMatches = tournamentMatches.filter(match=>match.time_start>=time).sort((match1,match2)=>match1.time_start>match2.time_start ? 1 : -1);

    return(
        <div>
            <UpcomingMatchesTable
            matches={upcomingMatches}
            />
            <div
            style={{height:70}}
            ></div>
            <FinishedMatchesTable
            matches={finishedMatches}
            />
        </div>
    )
}

export default BetsPage;