import { useState } from "react";
import { useTranslate } from "react-translate";
import { ModerationsService } from "../../modules/moderations/ModerationsService";
import { Tournament } from "../../modules/tournaments/model/tournament.model";
import { useTypedSelector } from "../../store/root";
import StandardModal, { StandardModalProps } from "../modal/StandardModal";

const ParticipantAddModal: React.FC<StandardModalProps&{tournament:Tournament}> = (props)=>{

    const {onClose,show,tournament} = props;
    const t = useTranslate("participantsTable");
    const users = useTypedSelector(state=>state.tournaments.users);

    const [userSlug,setUserSlug] = useState<string>("");

    const participantAdd = ()=>{

        const userId = users.find(user=>user.slug===userSlug)?._id;

        if(!userId){
            return
        }

        const moderationService = ModerationsService.get();
        moderationService.participantAdd(tournament._id,userId);
    }

    return (
        
        <StandardModal
        show={show}
        onExit={onClose}
        onSave={()=>{
            onClose();
            participantAdd();
        }}
        saveEnabled={true}
        title={t("ADD_PARTICIPANT_MODAL_HEADER")}
        >
        <p>{t("ADD_MODERATION_MODAL_DESC",{tournamentName:tournament.name})}</p>
        <div className="input-group mb-3">
          <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("ADD_PARTICIPANT_MODAL_INPUT_USERSLUG")}
          </span>
          <input
            value={userSlug}
            onChange={(e)=>setUserSlug(e.target.value)}
            type="text"
            className="form-control"
            id="basic-url"
            aria-describedby="basic-addon3"
          />
        </div>

        </StandardModal>
    );
}

export default ParticipantAddModal;