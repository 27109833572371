import StandardModal, { StandardModalProps } from "../modal/StandardModal";
import { useTranslate } from "react-translate";
import { UserSevice } from "../../modules/user/userService";
import { useEffect, useState } from "react";
import { useTypedSelector } from "../../store/root";

const MIN_CHAR_PASSWORD=8;

const PasswordChangeModal: React.FC<StandardModalProps> = (props)=>{
    const {onClose,show} = props;
    const t = useTranslate("profilPage");

    const username = useTypedSelector(state=>state.auth.userData!.username);
    const [password, setPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [newPassword2, setNewPassword2] = useState<string>("");

    const changePasswordModal =()=>{
        const userService = UserSevice.get();
        userService.userChangePassword(username,password,newPassword).then(result=>{
          if(!result){
            alert(t("PASSWORD_CHANGE_MODAL_WRONGPASSWORD_ERROR"))
          }else{
            alert(t("PASSWORD_CHANGE_MODAL_ACCEPTED"))

          }
        }).catch(()=>{
          alert(t("PASSWORD_CHANGE_MODAL_UNKNOWN_ERROR"))
        });
    }

    useEffect(()=>{
      setPassword("");
      setNewPassword("");
      setNewPassword2("");
    },[show])

    return (
        <StandardModal
          show={show}
          onExit={onClose}
          onSave={() => {

            if(newPassword!==newPassword2){
              alert(t("PASSWORD_CHANGE_MODAL_MATCH_ERROR"))
              return;
            }
            if(newPassword.length<MIN_CHAR_PASSWORD){
              alert(t("PASSWORD_CHANGE_MODAL_PASSWORDLENGTH_ERROR"))
              return;
            }
            changePasswordModal();
            onClose();
          }}
          saveEnabled={true}
          title={t("PASSWORD_CHANGE_MODAL_HEADER")}
        >
        <p>{t("PASSWORD_CHANGE_MODAL_DESC")}</p>

        <div className="input-group mb-3">
            <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("PASSWORD_CHANGE_MODAL_INPUT_PASSWORD")}
    
            </span>
            <input
              value={password}
              onChange={(e)=>setPassword(e.target.value)}
              type="password"
              className="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
            />
          </div>
          <div className="input-group mb-3">
            <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("PASSWORD_CHANGE_MODAL_INPUT_NEWPASSWORD")}
    
            </span>
            <input
              value={newPassword}
              onChange={(e)=>setNewPassword(e.target.value)}
              type="password"
              className="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
            />
          </div>
          <div className="input-group mb-3">
            <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("PASSWORD_CHANGE_MODAL_INPUT_NEWPASSWORD2")}
    
            </span>
            <input
              value={newPassword2}
              onChange={(e)=>setNewPassword2(e.target.value)}
              type="password"
              className="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
            />
          </div>


        </StandardModal>
    );
}

export default PasswordChangeModal;