import { Tournament } from "../../modules/tournaments/model/tournament.model"
import StandardModal, { StandardModalProps } from "../modal/StandardModal"
import { MatchUpdateModalData } from "./MatchUpdateModal"
import { useTranslate } from "react-translate";
import { ModerationsService } from "../../modules/moderations/ModerationsService";

export type MatchDeleteModalData={
    matchId:number,
    matchTeam1:string,
    matchTeam2:string,
}

const MatchDeleteModal:React.FC<StandardModalProps&{
    tournament:Tournament,
    data:MatchDeleteModalData|undefined
}>=(props)=>{
    const t = useTranslate("matchesTable");
    const {data,onClose,show,tournament} = props;

    if(!data){
        return null;
    }

    const matchDelete=()=>{
        const moderationService = ModerationsService.get();
        moderationService.matchDelete(tournament._id,data.matchId);
    }

    return (
        <StandardModal
        show={show}
        onExit={onClose}
        onSave={()=>{
            onClose();
            matchDelete();
        }}
        saveEnabled={true}
        title={t("MATCH_DELETE_MODAL_HEADER")}
        >
            {t("MATCH_DELETE_MODAL_DESC",{team1:data.matchTeam1,team2:data.matchTeam2})}
        </StandardModal>);;
}

export default MatchDeleteModal;