import { UserState } from ".";
import { Bet } from "../../tournaments/model/bet.model";

export type SetUserBetsPayload={
    userBets:Array<Bet>
}

export const setUserBets = (state:UserState,payload:SetUserBetsPayload):UserState=>{
    const {userBets} = payload;

    return{
        ...state,
        userBets
    }
}