import { AuthState } from "."

export type SetAccessTokenPayload= {
    accessToken:string
}

export const setAccessToken = (prevState:AuthState,payload:SetAccessTokenPayload):AuthState=>{

    const {loggedIn} = prevState;
    const {accessToken} = payload;

    if(!loggedIn){
        return prevState;
    }

    return{
        ...prevState,
        accessToken
    }
}