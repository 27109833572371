import { Store } from "redux";
import { AvailableLanguageTags } from "./models/available-language-tags";
import { callChangeLanguage } from "./store";

export class TranslateService {
    private constructor(private store: Store) {
    }
  
    private static self: TranslateService;
    static init(store: Store) {
      this.self = new this(store);
      return this.get();
    }
    static get() {
      if (!this.self) {
        throw Error("Not initialized yet.");
      }
      return this.self;
    }

    changeLanguage(languageTag:AvailableLanguageTags){
        this.dispatchChangeLanguage(languageTag);
    }

    private dispatchChangeLanguage(languageTag:AvailableLanguageTags){
        this.store.dispatch(callChangeLanguage({languageTag}))
    }

}