import { AvailableLanguageTags } from "./models/available-language-tags";
import Cookies from 'universal-cookie';

const LANGUAGE_COOKIE_NAME="SELECTED_LANGUAGE"

const getBrowserLanguage=():AvailableLanguageTags=>{
    return navigator.language==="pl-PL" ?  AvailableLanguageTags.PL : AvailableLanguageTags.EN;
}

export const getLanguageCookie=():AvailableLanguageTags=>{
    const cookies = new Cookies();
    let languageTag = cookies.get(LANGUAGE_COOKIE_NAME) as AvailableLanguageTags|undefined;

    if(!languageTag){
        languageTag=getBrowserLanguage();
    }

    return languageTag;
}

export const setLanguageCookie=(languageTag:AvailableLanguageTags)=>{
    const cookies = new Cookies();
    cookies.set(LANGUAGE_COOKIE_NAME,languageTag);
}