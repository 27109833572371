import { matchPath } from "react-router";
import { Tournament } from "../modules/tournaments/model/tournament.model";
import {TOURNAMENT_PAGES,STANDARD_PAGES,TOURNAMENTS_PAGE} from '../components/page-skeleton/Navbar';

export const getInitTournament = (tournaments:Array<Tournament>,pathname:string):Tournament|null=>{
    const tournamentPageData = isTournamentPage(pathname);
    if(tournamentPageData.matched){
        const tournament = tournaments.find(tournament=>tournament.slug===tournamentPageData.tournamentSlug)
        if(tournament){
          return tournament;
        }
    }

    if(tournaments.length){
      return tournaments[0]
    }

    return null;
  }

export const isTournamentPage=(pathname:string):{matched:false}|{matched:true,tournamentSlug:string,tournamentSubpage:TOURNAMENT_PAGES}=>{

    const result = matchPath(pathname,{
      path:`/${TOURNAMENTS_PAGE}/:tournamentSlug/:tournamentSubpage`
    })
    if(!result){
      return {matched:false}
    }

    const tournamentSlug = (result.params as any).tournamentSlug as string;
    const tournamentSubpage = (result.params as any).tournamentSubpage as TOURNAMENT_PAGES

    return {matched:true,
        tournamentSlug,
        tournamentSubpage
    }

}

export const isStandardPage=(pathname:string):{matched:false}|{matched:true,page:STANDARD_PAGES}=>{

    const result = matchPath(pathname,{
      path:`/:page`
    })

    if(!result){
      return {matched:false}
    }

    const page = (result.params as any).page as STANDARD_PAGES;

    if(!Object.values(STANDARD_PAGES).includes(page)){
      return {matched:false}
    }

    return{
      matched:true,
      page
    }

  }

export const navigateToTournamentPage = (history:any,tournamentSlug:string,tournamentSubpage:TOURNAMENT_PAGES)=>{

        history.push(`/tournaments/${tournamentSlug}/${tournamentSubpage}`)
    
}

export const navigateToTournament = (history:any,pathname:string,tournamentSlug:string)=>{
    const tournamentPage = isTournamentPage(pathname);
    if(tournamentPage.matched){
        history.push(`/tournaments/${tournamentSlug}/${tournamentPage.tournamentSubpage}`)
    }
    else{
        history.push(`/tournaments/${tournamentSlug}/${TOURNAMENT_PAGES.BETS}`)
    }
}

export const navigateToPage = (history:any,pageName:STANDARD_PAGES)=>{
    history.push(`/${pageName}`)
}