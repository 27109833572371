export default{
    "FINISHED_MATCHES_TABLE_HEADER":"Finished matches",
    "FINISHED_MATCHES_TABLE_DESC":"All finished matches in tournament.",

    "UPCOMING_MATCHES_TABLE_HEADER":"Upcoming matches",
    "UPCOMING_MATCHES_TABLE_DESC":"All upcoming matches in tournament.",

    "COLUMN_TIMESTART":"Time start",
    "COLUMN_TEAM1":"Team 1",
    "COLUMN_TEAM2":"Team 2",
    "COLUMN_SCORE":"Score",
    "COLUMN_BET":"Bet",

    "UPCOMING_MATCHES_EDIT":"Edit bets",
    "UPCOMING_MATCHES_REVERT":"Revert bets",
    "UPCOMING_MATCHES_SAVE":"Save bets",

    "PUT_BET_ERROR":"We were unable to save score of atleast one match."

}