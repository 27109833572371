import { useLocation } from "react-router";
import { useTypedSelector } from "../../store/root";
import { isTournamentPage } from "../../utils/navbar";
import ScoreboardTable from "./ScoreboardTable"

const ScoreboardPage: React.FC = ()=>{

    const location = useLocation();
    const tournaments = useTypedSelector(state=>state.tournaments.tournamentsParticipate);
    const tournamentPageData = isTournamentPage(location.pathname);
    const loggedIn = useTypedSelector(state=>state.auth.loggedIn);

    if(!tournamentPageData.matched||!loggedIn){
        return null;
    }
    if(!tournaments.find(tournament=>tournament.slug===tournamentPageData.tournamentSlug)){
        return null;
    }

    return <>
    <ScoreboardTable/>
    </>;
}

export default ScoreboardPage