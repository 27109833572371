import axios from "axios";
import { Store } from "redux";
import { FULL_URL } from "../../config/server";
import { ReducersState } from "../../store/root";
import { AuthService } from "../auth/auth.service";
import { User } from "../auth/model/user-data.model";
import { Bet } from "./model/bet.model";
import { Match } from "./model/match.model";
import { Tournament } from "./model/tournament.model";
import { callSetTournamentsData } from "./store";

const REFRESH_INTERVAL_S = 60;

export class TournamentsService {
    private constructor(private store: Store,private authService:AuthService) {
      this.setSubscribers();
    }
  
    private static self: TournamentsService;
    static init(store: Store,authService:AuthService) {
      this.self = new this(store,authService);
      return this.get();
    }
    static get() {
      if (!this.self) {
        throw Error("Not initialized yet.");
      }
      return this.self;
    }

    private setSubscribers(){

        let prevUserSlug:string|false = false;

        this.store.subscribe(()=>{
            const state = this.store.getState() as ReducersState;
            
            const userSlug:string|false = state.auth.loggedIn && state.auth.userData!?.slug;

            if(prevUserSlug===userSlug){
                return null;
            }
            prevUserSlug=userSlug;
            if(!userSlug){
                disableRefreshInterval()
                this.clearData();
                return
            }
            enableRefreshInterval();
            this.updateData();
        })

        let refreshInterval:ReturnType<typeof setInterval>|null=null;

        const enableRefreshInterval=()=>{
            if(refreshInterval){
                return;
            }
            refreshInterval=setInterval(()=>{
                this.updateData();
            },REFRESH_INTERVAL_S*1000)
        }

        const disableRefreshInterval=()=>{
            if(!refreshInterval){
                return;
            }
            clearInterval(refreshInterval);
            refreshInterval=null;
        }

    }

    private clearData(){
        this.dispatchSetTournametsData({bets:[],matches:[],tournaments:[],tournamentsParticipate:[],users:[]})
    }

    async updateData(){
        const userId = (this.store.getState() as ReducersState).auth.userData!._id;
        const accessToken = this.authService.getAccessToken();

        /* this.updateUsersData();
        const tournaments = await this.updateTournamentData(userId);
        const matches = await this.updateMatchesData(tournaments)
        // const now = moment().unix();
        // const finishedMatches = matches.filter(match=>match.time_start<now);
        this.updateBetsData(matches); */

        const data = await this.sendGetAllRequest(accessToken);
        const tournamentsParticipate = this.filterUsersTournaments(data.tournaments,userId);
        (data as any).tournamentsParticipate = tournamentsParticipate
        this.dispatchSetTournametsData(data);
    }

    private filterUsersTournaments(tournaments:Array<Tournament>,userId:number){
        return tournaments.filter(tournament=>tournament.participants.includes(userId));
    }

    private async updateTournamentData(userId:number){
        const accessToken = this.authService.getAccessToken();
        const tournaments = await (await this.sendListTournamentsRequest(accessToken))   
        const tournamentsParticipate = tournaments.filter(tournament=>tournament.participants.includes(userId));
        this.dispatchSetTournametsData({tournaments,tournamentsParticipate})

        return tournamentsParticipate;
    }

    private async updateMatchesData(tournaments:Array<Tournament>){

        const accessToken = this.authService.getAccessToken();
        const tournamentsId = tournaments.map(tournament=>tournament._id);
        const tournamentsMatchesReq = tournamentsId.map(tournamentId=>this.sendListMatchesRequest(accessToken,tournamentId))
        const tournamentsMatches = await Promise.all(tournamentsMatchesReq);
        const matches = tournamentsMatches.reduce((arr:Array<Match>,tournamentMatches)=>{
            arr.push(...(tournamentMatches))
            return arr},[])
        this.dispatchSetTournametsData({matches});
        return matches;
    }

    private async updateBetsData(matches:Array<Match>){
        const accessToken = this.authService.getAccessToken();
        const matchesData = matches.map(match=>({tournamentId:match.tournament,matchId:match._id}));
        const matchesBetsReq = matchesData.map(matchData=>this.sendListBetsRequest(accessToken,matchData.tournamentId,matchData.matchId));
        const matchesBets = await Promise.all(matchesBetsReq);
        const bets = matchesBets.reduce((arr:Array<Bet>,matchBets)=>{
            arr.push(...(matchBets))
            return arr},[])
        this.dispatchSetTournametsData({bets})
        return bets;
    }
    
    private async updateUsersData(){
        const accessToken = this.authService.getAccessToken();
        const users = await this.sendListUsersRequest(accessToken);
        this.dispatchSetTournametsData({users})
    }


    private dispatchSetTournametsData(data:{tournaments?:Array<Tournament>,tournamentsParticipate?:Array<Tournament>,bets?:Array<Bet>,matches?:Array<Match>,users?:Array<User>}){
        this.store.dispatch(callSetTournamentsData(data))
    }

    private async sendListTournamentsRequest(accessToken:string){
        return (await axios.get(`${FULL_URL}api/tournaments`,{headers:{
            'Authorization': `Bearer ${accessToken}`
            }})).data as Array<Tournament>
    }

    private async sendListMatchesRequest(accessToken:string,tournamentId:number){
        return (await axios.get(`${FULL_URL}api/tournaments/${tournamentId}/matches`,{headers:{
            'Authorization': `Bearer ${accessToken}`
            }})).data as Array<Match>
    }

    private async sendListBetsRequest(accessToken:string,tournamentId:number,matchId:number){
        return (await axios.get(`${FULL_URL}api/tournaments/${tournamentId}/matches/${matchId}/bets`,{headers:{
            'Authorization': `Bearer ${accessToken}`
            }})).data as Array<Bet>
    }

    private async sendListUsersRequest(accessToken:string){
        return (await axios.get(`${FULL_URL}api/users`,{headers:{
            'Authorization': `Bearer ${accessToken}`
            }})).data as Array<User>
    }

    private async sendGetAllRequest(accessToken:string){
        return (await axios.get(`${FULL_URL}api/get-all`,{headers:{
            'Authorization': `Bearer ${accessToken}`
            }})).data as {users:Array<User>,tournaments:Array<Tournament>,matches:Array<Match>,bets:Array<Bet>}
    }
}