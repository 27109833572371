export default {
    "TABLE_HEADER":"Tabela uczestników",
    "TABLE_DESC":"Wszyscy uczestnicy turnieju",
    "COLUMN_USERNAME":"Nazwa użytkownika",
    "COLUMN_DISPLAY":"Wyświetlane",
    "COLUMN_SLUG":"Link",
    
    "ADD_PARTICIPANT_MODAL_HEADER":"Dodaj nowego uczestnika turnieju",
    "ADD_MODERATION_MODAL_DESC":"Dodaj nowego uczestnika to turnieju {{tournamentName}}",
    "ADD_PARTICIPANT_MODAL_INPUT_USERSLUG":"Link użytkownika",

    "REMOVE_PARTICIPANT_MODAL_HEADER":"Usuń uczestnika z turnieju",
    "REMOVE_PARTICIPANT_MODAL_DESC":"Usuń uczestnika {{username}} z turnieju {{tournamentName}}",
}