import { Tournament } from "../../modules/tournaments/model/tournament.model";
import { useTranslate } from "react-translate";
import { useTypedSelector } from "../../store/root";
import { useMemo, useState } from "react";
import { useTable } from "react-table";

import deleteIcon from '../../assets/admin-panel/icons/trash-fill.svg'
import addIcon from '../../assets/admin-panel/icons/plus-square-fill.svg'
import ParticipantAddModal from "./ParticipantAddModal";
import ParticipantRemoveModal, { ParticipantRemoveModalData } from "./ParticipantRemoveModal";

const ParticipantsTable: React.FC<{tournament:Tournament|undefined}>=(props)=>{

    const {tournament} = props;
    const t = useTranslate("participantsTable");

    const users = useTypedSelector(state=>state.tournaments.users);
    const participants = useMemo(()=>users.filter(user=>tournament?.participants.includes(user._id)),[users,tournament]);

    const [showParticipantAddModal,setShowParticipantAddModal] = useState<boolean>(false);

    const [showParticipantRemoveModal,setShowParticipantRemoveModal] = useState<boolean>(false);
    const [participantRemoveModalData,setParticipantRemoveModalData] = useState<ParticipantRemoveModalData|null>(null);

    const columns = useMemo(()=>[

        {
            Header: t("COLUMN_USERNAME"),
            accessor: "username",
          },
          {
              Header: t("COLUMN_DISPLAY"),
              accessor: "display",
            },
          {
            Header: t("COLUMN_SLUG"),
            accessor: "slug",
          },
    ],[])

    const data = useMemo(()=>
        participants.map(participant=>({
            username:participant.username,
            display:participant.display,
            slug:participant.slug,
            id:participant._id,
        }))
    ,[participants])

    const tableInstance = useTable({
        columns:columns as any,
        data,
      })
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;

    if(!tournament){
        return null;
    }


    return (
        <div>
      <h3>
        {t("TABLE_HEADER")}
      </h3>
      <p>
        {t("TABLE_DESC")}
      </p>

        <table className="table" {...getTableProps()}>
            <thead>
            {// Loop over the header rows
            headerGroups.map(headerGroup => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                headerGroup.headers.map(column => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()} scope="col">
                    {// Render the header
                    column.render('Header')}

                    {/* Add a sort direction indicator */}
                    </th>
                ))}
                <th>
                    
                </th>
                </tr>
            ))}
            </thead>
            {/* Apply the table body props */}
            <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
            rows.map(row => {
                // Prepare the row for display
                prepareRow(row)
                return (
                // Apply the row props
                <tr {...row.getRowProps()}>
                    {// Loop over the rows cells
                    row.cells.map(cell => {
                    // Apply the cell props
                    return (
                        <td {...cell.getCellProps()}>
                        {// Render the cell contents
                        cell.render('Cell')}
                        </td>
                    )
                    })}
                    <td style={{cursor:"pointer"}} onClick={()=>{
                        setParticipantRemoveModalData({userId:row.original.id,userName:row.original.username})
                        setShowParticipantRemoveModal(true);

                    }}>
                        <img src={deleteIcon} alt="edit"/>
                    </td>
                </tr>
                )
            })}
            </tbody>
        </table>    
        <div style={{margin:8,marginRight:30,textAlign:"right"}}>
            <img onClick={()=>{setShowParticipantAddModal(true)}} style={{cursor:"pointer",height:24,width:24}} src={addIcon} alt="add"/>
        </div>
        
        <ParticipantAddModal
        onClose={()=>setShowParticipantAddModal(false)}
        show={showParticipantAddModal}
        tournament={tournament}
        />

        <ParticipantRemoveModal
        onClose={()=>setShowParticipantRemoveModal(false)}
        show={showParticipantRemoveModal}
        tournament={tournament}
        data={participantRemoveModalData}
        />

    </div>
    );
}

export default ParticipantsTable;