import { AuthState } from ".";
import { User } from "../model/user-data.model";

export type SetUserDataPayload = {
    userData:User
}

export const setUserData = (state:AuthState,payload:SetUserDataPayload):AuthState=>{
    const {userData} = payload;

    return {
        ...state,
        userData
    }
}