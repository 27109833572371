import { LoadedAdminState } from "../../modules/admin/store";
import { useTypedSelector } from "../../store/root";
import { useTable } from 'react-table';
import { useMemo, useState } from "react";
import editIcon from '../../assets/admin-panel/icons/pencil-fill.svg'
import addIcon from '../../assets/admin-panel/icons/plus-square-fill.svg'
import {useTranslate} from 'react-translate';
import PromotionModal, { PromotionModalData } from "./PromotionModal";
import CreateUserModal from "./CreateUserModal";
import PasswordChangeModal, {PasswordChangeModalData} from "./PasswordChangeModal";

const UsersTable:React.FC=()=>{

    const {users} = useTypedSelector(state=>(state.admin as LoadedAdminState).adminData);
    const superuser = useTypedSelector(state=>(state.admin as LoadedAdminState).superuser);

    const [showPromotionModal,setShowPromotionModal] = useState<boolean>(false);
    const [promotionModalData,setPromotionModalData] = useState<PromotionModalData|null>(null);

    const [showCreateUserModal,setShowCreateUserModal] = useState<boolean>(false);

    const [showChangePasswordModal,setShowChangePasswordModal] = useState<boolean>(false);
    const [changePasswordModalData,setChangePasswordModalData] = useState<PasswordChangeModalData|undefined>(undefined)

    const t = useTranslate("usersTable")

    const columns = useMemo(
        () => [
          {
            Header: t("USERNAME"),
            accessor: 'username',
          },
          {
            Header: t("DISPLAY"),
            accessor: 'display',
          },
          {
            Header: t("SLUG"),
            accessor: 'slug',
          },
          {
            Header: t("ADMIN"),
            accessor: 'admin',
          },
          
        ],
        [t]
      )

      const data = useMemo(()=>
        users.map(user=>({
            username:user.username,
            display:user.display,
            slug:user.slug,
            admin:user.admin ? t("ADMIN_YES") : t("ADMIN_NO"),
            _id:user._id
        }))
      ,[users,t])

      const tableInstance = useTable({
        columns:columns as any,
        data,
      })
    
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = tableInstance;
    

    return (
        <div>
          <h3>
            {t("TABLE_HEADER")}
          </h3>
          <p>
            {t("TABLE_DESC")}
          </p>

            <table className="table" {...getTableProps()}>
                <thead>
                {// Loop over the header rows
                headerGroups.map(headerGroup => (
                    // Apply the header row props
                    <tr {...headerGroup.getHeaderGroupProps()}>
                    {// Loop over the headers in each row
                    headerGroup.headers.map(column => (
                        // Apply the header cell props
                        <th {...column.getHeaderProps()} scope="col">
                        {// Render the header
                        column.render('Header')}

                        {/* Add a sort direction indicator */}
                        </th>
                    ))}
                    {!superuser ? "" :
                    <th>
                        
                    </th>
                    }
                    <th>
                        
                    </th>
                    </tr>
                ))}
                </thead>
                {/* Apply the table body props */}
                <tbody {...getTableBodyProps()}>
                {// Loop over the table rows
                rows.map(row => {
                    // Prepare the row for display
                    prepareRow(row)
                    return (
                    // Apply the row props
                    <tr {...row.getRowProps()}>
                        {// Loop over the rows cells
                        row.cells.map(cell => {
                        // Apply the cell props
                        return (
                            <td {...cell.getCellProps()}>
                            {// Render the cell contents
                            cell.render('Cell')}
                            </td>
                        )
                        })}
                        {!superuser ? "" :
                        <td style={{cursor:"pointer"}} onClick={()=>{
                          setPromotionModalData({userId:row.original._id})
                          setShowPromotionModal(true);
                        }}>
                            <img src={editIcon} alt="edit"/>
                        </td>
                        }
                        <td style={{cursor:"pointer"}} onClick={()=>{
                          setChangePasswordModalData({userId:row.original._id,username:row.original.username})
                          setShowChangePasswordModal(true);
                        }}>
                            <img src={editIcon} alt="edit"/>
                        </td>
                    </tr>
                    )
                })}
                </tbody>
            </table>    
            <div style={{margin:8,marginRight:30,textAlign:"right"}}>
                <img onClick={()=>setShowCreateUserModal(true)} style={{cursor:"pointer",height:24,width:24}} src={addIcon} alt="add"/>
            </div>
    
            <PromotionModal
            data={promotionModalData}
            onClose={()=>setShowPromotionModal(false)}
            show={showPromotionModal}
            />
            <CreateUserModal
            onClose={()=>setShowCreateUserModal(false)}
            show={showCreateUserModal}
            />

            <PasswordChangeModal
            show={showChangePasswordModal}
            onClose={()=>setShowChangePasswordModal(false)}
            data={changePasswordModalData}
            />

        </div>
    );
}

export default UsersTable