export default{
    "FINISHED_MATCHES_TABLE_HEADER":"Zakończone mecze",
    "FINISHED_MATCHES_TABLE_DESC":"Wszystkie zakończone mecze w turnieju.",

    "UPCOMING_MATCHES_TABLE_HEADER":"Nadchodzące mecze",
    "UPCOMING_MATCHES_TABLE_DESC":"Wszystkie nadchodzące mecze w turnieju.",

    "COLUMN_TIMESTART":"Czas rozpoczęcia",
    "COLUMN_TEAM1":"Drużyna 1",
    "COLUMN_TEAM2":"Drużyna 2",
    "COLUMN_SCORE":"Wynik",
    "COLUMN_BET":"Typ",

    "UPCOMING_MATCHES_EDIT":"Edytuj typy",
    "UPCOMING_MATCHES_REVERT":"Przywróć typy",
    "UPCOMING_MATCHES_SAVE":"Zapisz typy",

    "PUT_BET_ERROR":"Nie udało się wprowadzić wyniku jednego lub więcej meczów. Przepraszamy."
}