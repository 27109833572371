export default {
    "TABLE_HEADER":"Użytkownicy",
    "TABLE_DESC":"Wszyscy użytkownicy w typerze",
    "USERNAME":"Nazwa użytkownika",
    "DISPLAY":"Wyświetlane",
    "SLUG":"Link",
    "ADMIN":"Administrator",
    "ADMIN_YES":"Tak",
    "ADMIN_NO":"Nie",
    
    "PROMOTE_MODAL_HEADER":"Awansuj użytkownika",
    "PROMOTE_MODAL_DESC":"Awansuj użytkownika {{username}} do admina.",
    
    "PROMOTE_MODAL_HEADER_DEGRADE":"Degraduj użytkownika",
    "PROMOTE_MODAL_DESC_DEGRADE":"Degraduj użytkownika {{username}} do zwykłego użytkownika",

    "CREATE_USER_MODAL_HEADER":"Utwórz użytkownika",
    "CREATE_USER_MODAL_DESC":"Utwórz nowego użytkownika.",
    "CREATE_USER_MODAL_INPUT_USERNAME":"Nazwa użytkownika",
    "CREATE_USER_MODAL_INPUT_PASSWORD":"Hasło",
    "CREATE_USER_MODAL_INPUT_DISPLAY":"Wyświetlane",

    "PASSWORD_CHANGE_MODAL_HEADER":"Ustaw nowe hasło",
    "PASSWORD_CHANGE_MODAL_DESC":"Ustaw nowe hasło użytkownika {{username}}.",
    "PASSWORD_CHANGE_MODAL_INPUT_PASSWORD":"Nowe hasło",
}