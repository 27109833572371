import { useEffect, useState } from "react";
import { matchPath, useHistory, useLocation } from "react-router";
import { useTranslate } from "react-translate";
import { AuthService } from "../../modules/auth/auth.service";
import { Tournament } from "../../modules/tournaments/model/tournament.model";
import { useTypedSelector } from "../../store/root";
import { getInitTournament, isTournamentPage, navigateToPage, navigateToTournament, navigateToTournamentPage } from "../../utils/navbar";
import LanguageSelect from "./LanguageSelect";
import LoginModal from "./LoginModal";

export enum TOURNAMENT_PAGES{
  "BETS"="bet",
  "SCOREBOARD"="scoreboard",
  "TABLE"="table"
}

export const TOURNAMENTS_PAGE="tournaments"

export enum STANDARD_PAGES{
  "ADMIN"="admin",
  "MOD"="mod",
  "PROFIL"="profil"
}


const Navbar: React.FC = () => {
  const t = useTranslate("navbar");
  
  const history = useHistory();
  const location = useLocation();

  const tournamentsParticipate = useTypedSelector(state=>state.tournaments.tournamentsParticipate);
  const [selectedTournament,setSelectedTournament] = useState<Tournament|null>(getInitTournament(tournamentsParticipate,location.pathname))

  const admin = useTypedSelector(state=>state.auth.userData?.admin);
  const isAdmin = admin || false;

  const moderatedTournaments = useTypedSelector(state=>state.moderations.moderatedTournaments)
  const isMod = !!moderatedTournaments.length;

  const isLoggedIn = useTypedSelector(state=>state.auth.loggedIn)

  const [showLoginModal,setShowLoginModal] = useState<boolean>(false);

  useEffect(()=>{
    if(!selectedTournament){
      return;
    }

    if(isTournamentPage(location.pathname).matched){
    navigateToTournament(history,location.pathname,selectedTournament.slug)
    }
  },[selectedTournament])

  useEffect(()=>{
    const tournamentStillParticipate = tournamentsParticipate.find(tournament=>tournament.slug===selectedTournament?.slug)
    if(tournamentStillParticipate){
      return;
    }
    setSelectedTournament(getInitTournament(tournamentsParticipate,location.pathname))
  },[tournamentsParticipate])

  return (
    <div className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a
          className="navbar-brand"
          onClick={(e) => {
            e.preventDefault();
          }}
          href="#"
        >
          {t("TYPER_2")}
        </a>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {!selectedTournament ? "" : selectedTournament.name}
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                {tournamentsParticipate.map((tournament,i)=>(
                <li key={i}>
                  <a className="dropdown-item" onClick={e=>{e.preventDefault(); setSelectedTournament(tournament)}} href="#">
                    {tournament.name}
                  </a>
                </li>
                ))}
              </ul>
            </li>

{!selectedTournament ? "" :
<>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={(e) => {
                  navigateToTournamentPage(history,selectedTournament.slug,TOURNAMENT_PAGES.BETS)
                  e.preventDefault();
                }}
                href="#"
              >
                {t("PAGE_BETS")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={(e) => {
                  navigateToTournamentPage(history,selectedTournament.slug,TOURNAMENT_PAGES.SCOREBOARD)
                  e.preventDefault();
                }}
                href="#"
              >
                {t("PAGE_SCOREBOARD")}
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={(e) => {
                  navigateToTournamentPage(history,selectedTournament.slug,TOURNAMENT_PAGES.TABLE)
                  e.preventDefault();
                }}
                href="#"
              >
                {t("PAGE_TABLE")}
              </a>
            </li>
            </>
}
{!isMod ? "" :
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={(e) => {
                  navigateToPage(history,STANDARD_PAGES.MOD)
                  e.preventDefault();
                }}
                href="#"
              >
                {t("PAGE_MOD")}
              </a>
            </li>
}
{!isAdmin ? "" :
            <li className="nav-item">
              <a
                className="nav-link"
                onClick={(e) => {
                  navigateToPage(history,STANDARD_PAGES.ADMIN)
                  e.preventDefault();
                }}
                href="#"
              >
                {t("PAGE_ADMIN")}
              </a>
            </li>
              }
          </ul>
          <form className="d-flex">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              
{!isLoggedIn ? "" :
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={(e) => {
                  navigateToPage(history,STANDARD_PAGES.PROFIL)
                  e.preventDefault();
                  }}
                  href="#"
                >
                  {t("PAGE_PROFIL")}
                </a>
              </li>
}
{!isLoggedIn ? 
  <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={(e) => {
                    setShowLoginModal(true);
                    e.preventDefault();
                  }}
                  href="#"
                >
                  {t("LOGIN")}
                </a>
              </li>
 :
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={(e) => {
                    const authService = AuthService.get();
                    authService.logout();
                    e.preventDefault();
                  }}
                  href="#"
                >
                  {t("LOGOUT")}
                </a>
              </li>
}
              <LanguageSelect/>
            </ul>
          </form>
        </div>
      </div>
      <LoginModal
      show={showLoginModal}
      onClose={()=>setShowLoginModal(false)}
      />
    </div>
  );
};

export default Navbar;
