import StandardModal, { StandardModalProps } from "../modal/StandardModal";
import {useTranslate} from "react-translate";
import { useTypedSelector } from "../../store/root";
import { LoadedAdminState } from "../../modules/admin/store";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { AdminService } from "../../modules/admin/admin.service";


export type PromotionModalData = {
    userId:number
}

const PromotionModal: React.FC<StandardModalProps&{data:PromotionModalData|null}>=(props)=>{

    const {data,show,onClose} = props;
    const t = useTranslate("usersTable");

    const {users} = useTypedSelector(state=>(state.admin as LoadedAdminState).adminData);

    if(!data){
        return null;
    }

    const user = users.find(user=>user._id===data.userId)

    if(!user){
        return null;
    }

    const userIsAdmin =user.admin

    const demoteUser = (userSlug:string)=>{
        const adminService = AdminService.get();
        adminService.userUpdateAdmin(userSlug,false);
    }

    const promoteUser = (userSlug:string)=>{
        const adminService = AdminService.get();
        adminService.userUpdateAdmin(userSlug,true);
    }


    return (
        <StandardModal
        show={show}
        onExit={onClose}
        onSave={()=>{
            onClose();
            if(userIsAdmin){
                demoteUser(user.slug)
            }else{
                promoteUser(user.slug)
            }
        }}
        saveEnabled={true}
        title={!userIsAdmin ? t("PROMOTE_MODAL_HEADER") : t("PROMOTE_MODAL_HEADER_DEGRADE")}
        >
{!userIsAdmin ? t("PROMOTE_MODAL_DESC",{username:user.username}) : t("PROMOTE_MODAL_DESC_DEGRADE",{username:user.username})}
        </StandardModal>
    );
}

export default PromotionModal;