import { TranslateState } from ".";
import { setLanguageCookie } from "../cache-saving.service";
import { AvailableLanguageTags } from "../models/available-language-tags";

export type ChangeLanguagePayload={
    languageTag:AvailableLanguageTags
}

export const changeLanguage = (_state:TranslateState,payload:ChangeLanguagePayload):TranslateState=>{
    const {languageTag} = payload;
    setLanguageCookie(languageTag);
    return {languageTag}
}