import { ToastMessage } from "../modules/toast-messages/models/toast-message.model";
import { ToastMessagesService } from "../modules/toast-messages/toast-messages.service";

export const GlobalMessages: Array<ToastMessage> = [
  // {
  //   id: "Message1",
  //   data: {
  //       extraButtons:[
  //           (props)=>{
  //               const {onClose,id} = props;
  //               onClose();
  //               const toastMessageService = ToastMessagesService.get();
  //               toastMessageService.markMessageAsReaded(id)
  //           }
  //       ],
  //     translations: {
  //       EN: {
  //         title: "Information",
  //         message: "In knockout stages matches score after 90 minutes is used (without extra time).",
  //         buttonsText:[
  //             "Don't show again"
  //         ]
  //       },
  //       PL: {
  //         title: "Informacja",
  //         message: "W meczach w fazie pucharowej liczy się wynik po 90 minutach (bez dogrywki).",
  //         buttonsText:[
  //             "Nie pokazuj ponownie"
  //         ]
  //       },
  //     },
  //   },
  // },
];
