import { Match } from "../../modules/tournaments/model/match.model";
import { useTranslate } from "react-translate";
import { useMemo } from "react";
import { useTypedSelector } from "../../store/root";
import { timestampToDisplay } from "../../utils/time";
import { getBetColor, getBetResultType } from "../../utils/betColors";
import { useTable } from "react-table";
import { findBet } from "./findBet";
import { isUndefined } from "lodash";

const FinishedMatchesTable: React.FC<{ matches: Array<Match> }> = (props) => {
  const { matches } = props;

  const t = useTranslate("betsPage");
  const bets = useTypedSelector((state) => state.user.userBets);

  const columns = useMemo(
    () => [
      {
        Header: t("COLUMN_TIMESTART"),
        accessor: "timeStart",
      },
      {
        Header: t("COLUMN_TEAM1"),
        accessor: "team1",
      },
      {
        Header: t("COLUMN_TEAM2"),
        accessor: "team2",
      },
      {
        Header: t("COLUMN_SCORE"),
        accessor: "score",
      },
      {
        Header: t("COLUMN_BET"),
        accessor: "bet",
      },
    ],
    [t]
  );

  const data = useMemo(
    () =>
      matches.map((match) => {
        const bet = findBet(bets,match);
        return {
          timeStart: timestampToDisplay(match.time_start),
          team1: match.team1,
          team2: match.team2,
          score: isUndefined(match.score1) ? "" : `${match.score1}:${match.score2}`,
          bet: isUndefined(bet) ? "" : `${bet?.score1}:${bet?.score2}`,
          betResultType: getBetResultType(match, bet),
        };
      }),
    [matches]
  );

  const tableInstance = useTable({
    columns: columns as any,
    data,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;
  return (
    <div>
      <h3>{t("FINISHED_MATCHES_TABLE_HEADER")}</h3>
      <p>{t("FINISHED_MATCHES_TABLE_DESC")}</p>

      <table className="table" {...getTableProps()}>
        <thead>
          {
            // Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {
                  // Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps()} scope="col">
                      {
                        // Render the header
                        column.render("Header")
                      }

                      {/* Add a sort direction indicator */}
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {
            // Loop over the table rows
            rows.map((row,i) => {
              // Prepare the row for display

              const color = getBetColor(i%2==0,row.original.betResultType)

              prepareRow(row);

              return (
                // Apply the row props
                <tr style={{backgroundColor:color}} {...row.getRowProps()}>
                  {
                    // Loop over the rows cells
                    row.cells.map((cell) => {
                      // Apply the cell props
                      return (
                        <td {...cell.getCellProps()}>
                          {
                            // Render the cell contents
                            cell.render("Cell")
                          }
                        </td>
                      );
                    })
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default FinishedMatchesTable;
