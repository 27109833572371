import React from "react";
import { createStore, Store } from "redux";
import { getReducers } from "./root";
import { Provider } from "react-redux";
import { TeamsService } from "../modules/teams/teams.service";
import { AuthService } from "../modules/auth/auth.service";
import { AdminService } from "../modules/admin/admin.service";
import { TranslateService } from "../modules/translate/translate.service";
import TranslationsProvider from "../translations/Provider";
import { TournamentsService } from "../modules/tournaments/TournamentsService";
import { ModerationsService } from "../modules/moderations/ModerationsService";
import { UserSevice } from "../modules/user/userService";
import RouterProvider from "../utils/RouterProvider";
import ToastMessagesProvider from "../modules/toast-messages/ToastMessagesProvider";
import { ToastMessagesService } from "../modules/toast-messages/toast-messages.service";

const store = createStore(getReducers()) as Store;
TeamsService.init(store);
const authService = AuthService.init(store);
AdminService.init(store);
TranslateService.init(store);
const tournamentsService = TournamentsService.init(store, authService);
ModerationsService.init(store, authService, tournamentsService);
UserSevice.init(store, authService);
ToastMessagesService.init(store);

export const StoreProvider: React.FC = (props) => {
  return (
    <Provider store={store}>
      <TranslationsProvider>
        <RouterProvider>
          <ToastMessagesProvider />
          {props.children}
        </RouterProvider>
      </TranslationsProvider>
    </Provider>
  );
};
