import axios from "axios";
import { Store } from "redux";
import { FULL_URL } from "../../config/server";
import { ReducersState } from "../../store/root";
import { User } from "../auth/model/user-data.model";
import { PointRules } from "../tournaments/model/point-rules.model";
import { Tournament, TournamentType } from "../tournaments/model/tournament.model";
import { callSetAdminData, callSetAdminInfo } from "./store";

export class AdminService {
    private constructor(private store: Store) {
      this.setSubscribers();
    }
  
    private static self: AdminService;
    static init(store: Store) {
      this.self = new this(store);
      return this.get();
    }
    static get() {
      if (!this.self) {
        throw Error("Not initialized yet.");
      }
      return this.self;
    }

    async userCreate(username:string,password:string,display:string){
      const accessToken = this.getAccessToken();
      await this.sendUserCreateRequest(accessToken,username,password,display);
      return this.updateAdminData();
    }

    async userUpdateAdmin(userSlug:string,admin:boolean){
      const accessToken = this.getAccessToken();
      await this.sendUserAdminUpdateRequest(accessToken,userSlug,admin);
      return this.updateAdminData();
    }

    async tournamentCreate(name:string,type:TournamentType,finished:boolean,pointRules:PointRules,time_start?:number,time_end?:number){
      const accessToken = this.getAccessToken();
      await this.sendTournamentCreateRequest(accessToken,name,type,finished,pointRules,time_start,time_end);
      return this.updateAdminData();
    }
    
    async tournamentDelete(tournamentId:number){
      const accessToken = this.getAccessToken();
      await this.sendTournamentDeleteRequest(accessToken,tournamentId);
      return this.updateAdminData();
    }

    async moderationAdd(tournamentId:number,userId:number){
      const accessToken = this.getAccessToken();
      await this.sendModerationAddRequest(accessToken,tournamentId,userId);
      return this.updateAdminData();
    }

    async moderationDelete(tournamentId:number,userId:number){
      const accessToken = this.getAccessToken();
      await this.sendModerationDeleteRequest(accessToken,tournamentId,userId);
      return this.updateAdminData();
    }

    async changePasswordAdmin(userId:number,newPassword:string){
      const accessToken = this.getAccessToken();
      await this.sendChangePasswordAdminRequest(accessToken,userId,newPassword);
      return this.updateAdminData();
    }

    private getAccessToken(){
      const accessToken = (this.store.getState() as ReducersState).auth.accessToken
      if(!accessToken){
        throw Error("No access token stored.")
      }
      return accessToken;
    }


    private setSubscribers(){

      let prevUserData:User|undefined=undefined;
      let prevAccessTokenSet:boolean=false;

      this.store.subscribe(()=>{
        const state = this.store.getState() as ReducersState;
        const {userData,accessToken} = state.auth;
        const accessTokenSet=!!accessToken;

        if(userData===prevUserData&&accessTokenSet===prevAccessTokenSet){
          return;
        }
        prevUserData=userData;
        prevAccessTokenSet=accessTokenSet;
        if(!userData||!accessTokenSet){
          return;
        }

        if(userData.admin){
          // console.log(userData)
          this.handleAdmin(!!userData.superuser);
        }
        else{
          this.handleNormalUser()
        }
      })
    }

    private handleNormalUser(){
      this.dispatchSetAdminInfo(false);
    }

    private async handleAdmin(superuser:boolean){
      // console.log(superuser)
      this.dispatchSetAdminInfo(true,superuser);
      this.updateAdminData();
    }

    private async updateAdminData(){

      const accessToken = this.getAccessToken();

      const tournamentRequest=axios.get(`${FULL_URL}api/tournaments`,{headers:{
      'Authorization': `Bearer ${accessToken}`
      }})
      const userRequest=axios.get(`${FULL_URL}api/users`,{headers:{
        'Authorization': `Bearer ${accessToken}`
        }})
      await Promise.all([tournamentRequest,userRequest]);
      const users = (await userRequest).data as Array<User>
      const tournaments = (await tournamentRequest).data as Array<Tournament>

      this.dispatchSetAdminData(tournaments,users)
    }

    private dispatchSetAdminData(tournaments:Array<Tournament>,users:Array<User>){
      this.store.dispatch(callSetAdminData({tournaments,users}))
    }
    
    private dispatchSetAdminInfo(admin:boolean,superuser?:boolean){
      if(admin){
        this.store.dispatch(callSetAdminInfo({admin,superuser:!!superuser}))
      }
      else{
        this.store.dispatch(callSetAdminInfo({admin}))
      }
    }

    private async sendUserCreateRequest(accessToken:string,username:string,password:string,display:string){
      return await axios.post(`${FULL_URL}api/users`,{username,password,admin:false,display},{headers:{
        'Authorization': `Bearer ${accessToken}`
        }})
    }

    private async sendUserAdminUpdateRequest(accessToken:string,userSlug:string,admin:boolean){
      return await axios.put(`${FULL_URL}api/users-set_admin/${userSlug}`,{admin},{headers:{
        'Authorization': `Bearer ${accessToken}`
        }})
    }

    private async sendTournamentCreateRequest(accessToken:string,name:string,type:TournamentType,finished:boolean,pointRules:PointRules,time_start?:number,time_end?:number){
      return await axios.post(`${FULL_URL}api/tournaments`,{name,type,finished,point_rules:pointRules,time_end,time_start},{headers:{
        'Authorization': `Bearer ${accessToken}`
        }})
    }

    private async sendTournamentDeleteRequest(accessToken:string,tournamentId:number){
      return await axios.delete(`${FULL_URL}api/tournaments/${tournamentId}`,{headers:{
        'Authorization': `Bearer ${accessToken}`
        }})
    }

    private async sendModerationAddRequest(accessToken:string,tournamentId:number,userId:number){
      return await axios.post(`${FULL_URL}api/tournaments/${tournamentId}/moderators`,{user_id:userId},{headers:{
        'Authorization': `Bearer ${accessToken}`
        }})   
    }
    private async sendModerationDeleteRequest(accessToken:string,tournamentId:number,userId:number){
      return await axios.delete(`${FULL_URL}api/tournaments/${tournamentId}/moderators/${userId}`,{headers:{
        'Authorization': `Bearer ${accessToken}`
        }})   
    }

    private async sendChangePasswordAdminRequest(accessToken:string,userId:number,newPassword:string){
      return await axios.put(`${FULL_URL}api/users/${userId}/password`,{newPassword},{headers:{
        'Authorization': `Bearer ${accessToken}`
        }})
    }
}