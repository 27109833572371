import { useState } from "react";
import { useTranslate } from "react-translate";
import { AdminService } from "../../modules/admin/admin.service";
import StandardModal, { StandardModalProps } from "../modal/StandardModal";

const CreateUserModal: React.FC<StandardModalProps> = (props) => {
  const { show, onClose } = props;
  const t = useTranslate("usersTable");

  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [display, setDisplay] = useState<string>("");

  const createUser = (username: string, password: string,display:string) => {
    const adminService = AdminService.get();
    adminService.userCreate(username, password,display);
  };

  return (
    <StandardModal
      show={show}
      onExit={onClose}
      onSave={() => {
        onClose();
        createUser(username, password,display);
      }}
      saveEnabled={true}
      title={t("CREATE_USER_MODAL_HEADER")}
    >
      <p>{t("CREATE_USER_MODAL_DESC")}</p>
      <div className="input-group mb-3">
        <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
          {t("CREATE_USER_MODAL_INPUT_USERNAME")}
        </span>
        <input
          value={username}
          onChange={(e)=>setUsername(e.target.value)}
          type="text"
          className="form-control"
          id="basic-url"
          aria-describedby="basic-addon3"
        />
      </div>
      <div className="input-group mb-3">
        <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
        {t("CREATE_USER_MODAL_INPUT_PASSWORD")}

        </span>
        <input
          value={password}
          onChange={(e)=>setPassword(e.target.value)}
          type="text"
          className="form-control"
          id="basic-url"
          aria-describedby="basic-addon3"
        />
      </div>
      <div className="input-group mb-3">
        <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
        {t("CREATE_USER_MODAL_INPUT_DISPLAY")}

        </span>
        <input
          value={display}
          onChange={(e)=>setDisplay(e.target.value)}
          type="text"
          className="form-control"
          id="basic-url"
          aria-describedby="basic-addon3"
        />
      </div>
    </StandardModal>
  );
};

export default CreateUserModal;
