export default{
    "COLUMN_USERNAME":"Nazwa użytkownika",
    "COLUMN_ADMIN":"Administrator",
    "COLUMN_ADMIN_YES":"Tak",
    "COLUMN_ADMIN_NO":"Nie",
    "COLUMN_SLUG":"Link",
    "COLUMN_DISPLAY":"Wyświetlane",

    "EDIT_PROFILE":"Edytuj profil",
    "CHANGE_PASSWORD":"Zmień hasło",

    "PASSWORD_CHANGE_MODAL_HEADER":"Zmień hasło",
    "PASSWORD_CHANGE_MODAL_DESC":"",
    "PASSWORD_CHANGE_MODAL_INPUT_PASSWORD":"Poprzednie hasło",
    "PASSWORD_CHANGE_MODAL_INPUT_NEWPASSWORD":"Nowe hasło",
    "PASSWORD_CHANGE_MODAL_INPUT_NEWPASSWORD2":"Powtórz nowe hasło",

    "PASSWORD_CHANGE_MODAL_ACCEPTED":"Hasło zmienione",
    "PASSWORD_CHANGE_MODAL_PASSWORDLENGTH_ERROR":"Podane hasło jest za krótkie. Hasło powinno mieć minimum 8 znaków.",
    "PASSWORD_CHANGE_MODAL_MATCH_ERROR":"Hasła nie są identyczne",
    "PASSWORD_CHANGE_MODAL_UNKNOWN_ERROR":"Nieznany błąd",
    "PASSWORD_CHANGE_MODAL_WRONGPASSWORD_ERROR":"Nieprawidłowe poprzednie hasło",
    
    "PROFILE_UPDATE_MODAL_HEADER":"Edytuj profil",
    "PROFILE_UPDATE_MODAL_DESC":"",
    "PROFILE_UPDATE_MODAL_INPUT_DISPLAY":"Wyświetlane",

}