import { Toast } from "bootstrap";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useTranslate } from "react-translate";
import { useHistory, useLocation } from "react-router";
import { useTypedSelector } from "../../../store/root";
import { ToastMessageData } from "../models/toast-message.model";
import { isNull } from "lodash";

export type ToastMessageProps = {
  id:string,
  show: boolean;
  onClose: () => void;
  data: ToastMessageData;
};


const ToastMessage: React.FC<ToastMessageProps> = (props) => {
  const { show, onClose, data } = props;
  const { extraButtons = [] } = data;
  const toastId = useMemo(() => `toast-${uuidv4()}`, []);
  const [toastObj, setToastObj] = useState<null | Toast>(null);
  const t = useTranslate("modal");
  const languageTag = useTypedSelector((state) => state.translate.languageTag);
  const translation = useMemo(() => {
    const { translations } = props.data;
    if (translations&&languageTag in translations) {
      return translations[languageTag];
    } else {
      return {};
    }
  }, [languageTag, props]);
  const { buttonsText = [], message, time, title } = translation;

  useEffect(() => {
    const registerHide = () => {
      onClose();
    };
    const toastEl = document.querySelector(`#${toastId}`) as any;
    const toast = new Toast(toastEl, { autohide: false });
    setToastObj(toast);
    toastEl.addEventListener("hidden.bs.toast", registerHide);
    return () => {
      toastEl.removeEventListener("hidden.bs.toast", registerHide);
    };
  }, [toastId]);

  // useEffect(()=>{
  //     if(toastObj){
  //         toastObj.show();
  //     }
  // },'hidd[toastObj])

  useEffect(() => {}, [toastObj]);

  useEffect(() => {
    if (!toastObj) {
      return;
    }
    if (show) {
      toastObj.show();
    } else {
      toastObj.hide();
    }
  }, [show, toastObj]);

  return (
    <div
      id={toastId}
      className="toast"
      role="alert"
      aria-live="assertive"
      aria-atomic="true"
    >
      <div className="toast-header">
        <strong className="me-auto">{title}</strong>
        {/* <small>{time}</small> */}
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="toast"
          aria-label="Close"
        ></button>
      </div>
      <div className="toast-body">
        {message}

        <div
          className="mt-2 pt-2 border-top"
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          {extraButtons.map((func, i) => (
            <button
              key={i}
              type="button"
              onClick={() => {if(!(isNull(func))){func(props)}}}
              className="btn btn-primary btn-sm"
              style={{ margin: 5 }}
            >
              {buttonsText[i]}
            </button>
          ))}
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            data-bs-dismiss="toast"
            style={{ margin: 5 }}
          >
            {t("CLOSE")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ToastMessage;
