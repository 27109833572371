import axios from "axios";
import { isUndefined } from "lodash";
import { Store } from "redux";
import { FULL_URL } from "../../config/server";
import { ReducersState } from "../../store/root";
import { AuthService } from "../auth/auth.service";
import { Tournament, TournamentType } from "../tournaments/model/tournament.model";
import { TournamentsService } from "../tournaments/TournamentsService";
import { callSetModeratedTournaments } from "./store";

export type TournamentUpdateData = {
  name?:string,
  type?:TournamentType,
  finished?:boolean,
  time_start?:number,
  time_end?:number,
  "point_rules.winner"?:number,
  "point_rules.score"?:number,
}

export type MatchUpdateData = {
  time_start?:number,
  team1?:string,
  team2?:string,
  score1?:number,
  score2?:number
}

export type MatchCreateData = {
  time_start:number,
  team1?:string,
  team2?:string,
}
export class ModerationsService {
    private constructor(private store: Store,private authService:AuthService,private tournamentsService:TournamentsService) {
      this.setSubscribers();
    }
  
    private static self: ModerationsService;
    static init(store: Store,authService:AuthService,tournamentsService:TournamentsService) {
      this.self = new this(store,authService,tournamentsService);
      return this.get();
    }
    static get() {
      if (!this.self) {
        throw Error("Not initialized yet.");
      }
      return this.self;
    }

    private setSubscribers(){
      
      let prevTournaments: Array<Tournament> = [];

      this.store.subscribe(()=>{
        const state = this.store.getState() as ReducersState;

        const {tournaments} = state.tournaments;

        if(prevTournaments===tournaments){
          return;
        }
        prevTournaments=tournaments;

        const userId = state.auth.userData?._id

        if(isUndefined(userId)){
          this.clearData();
          return;
        }
        const moderatedTournaments = tournaments.filter(tournament=>tournament.moderators.includes(userId));
        this.dispatchSetModeratedTournaments(moderatedTournaments);

      })

    }

    private clearData(){
      this.dispatchSetModeratedTournaments([]);
    }

    private dispatchSetModeratedTournaments(moderatedTournaments:Array<Tournament>){
      this.store.dispatch(callSetModeratedTournaments({moderatedTournaments}));
    }


    async tournamentUpdate(tournamentId:number,data:TournamentUpdateData){
      const accessToken = this.authService.getAccessToken();
      await this.sendTournamentUpdateRequest(accessToken,tournamentId,data)
      this.tournamentsService.updateData();
    }

    async participantAdd(tournamentId:number,userId:number){
      const accessToken = this.authService.getAccessToken();
      await this.sendParticipantAddRequest(accessToken,tournamentId,userId)
      this.tournamentsService.updateData();
    }

    async participantRemove(tournamentId:number,userId:number){
      const accessToken = this.authService.getAccessToken();
      await this.sendParticipantRemoveRequest(accessToken,tournamentId,userId);
      this.tournamentsService.updateData();
    }
    async matchCreate(tournamentId:number,data:MatchCreateData){
      const accessToken = this.authService.getAccessToken();
      await this.sendMatchCreateRequest(accessToken,tournamentId,data);
      this.tournamentsService.updateData();
    }
    async matchUpdate(tournamentId:number,matchId:number,data:MatchUpdateData){
      const accessToken = this.authService.getAccessToken();
      await this.sendMatchUpdateRequest(accessToken,tournamentId,matchId,data);
      this.tournamentsService.updateData();
    }
    async matchDelete(tournamentId:number,matchId:number){
      const accessToken = this.authService.getAccessToken();
      await this.sendMatchDeleteRequest(accessToken,tournamentId,matchId);
      this.tournamentsService.updateData();
    }
    
    private async sendTournamentUpdateRequest(accessToken:string,tournamentId:number,data:TournamentUpdateData){
      return await axios.put(`${FULL_URL}api/tournaments/${tournamentId}/`,data,{headers:{
        'Authorization': `Bearer ${accessToken}`
      }})   
    }
    
    private async sendParticipantAddRequest(accessToken:string,tournamentId:number,userId:number){
      return await axios.post(`${FULL_URL}api/tournaments/${tournamentId}/participants`,{user_id:userId},{headers:{
        'Authorization': `Bearer ${accessToken}`
      }})   
    }
    private async sendParticipantRemoveRequest(accessToken:string,tournamentId:number,userId:number){
      return await axios.delete(`${FULL_URL}api/tournaments/${tournamentId}/participants/${userId}`,{headers:{
        'Authorization': `Bearer ${accessToken}`
      }})   
      
    }
    private async sendMatchCreateRequest(accessToken:string,tournamentId:number,data:MatchCreateData){
      return await axios.post(`${FULL_URL}api/tournaments/${tournamentId}/matches`,data,{headers:{
        'Authorization': `Bearer ${accessToken}`
      }})   
    }
    private async sendMatchUpdateRequest(accessToken:string,tournamentId:number,matchId:number,data:MatchUpdateData){
      return await axios.put(`${FULL_URL}api/tournaments/${tournamentId}/matches/${matchId}`,data,{headers:{
        'Authorization': `Bearer ${accessToken}`
      }})   
      
    }
    private async sendMatchDeleteRequest(accessToken:string,tournamentId:number,matchId:number){
      return await axios.delete(`${FULL_URL}api/tournaments/${tournamentId}/matches/${matchId}`,{headers:{
        'Authorization': `Bearer ${accessToken}`
      }})   
      
      
    }
}
