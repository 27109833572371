import "./page.css";

const Page:React.FC = (props)=>{

    const {children} = props;

    return(
        <div id="page-container" style={{paddingTop:50,overflowX:"auto"}} className="container bg-light">
            {children}
        </div>
    );
}

export default Page;