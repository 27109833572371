import { AuthState } from ".";
import { User } from "../model/user-data.model";

export type SetAuthDataPayload={
    loggedIn: boolean;
    userData?:User;
    accessToken?:string;
}

export const setAuthData=(_prevState:AuthState,payload:SetAuthDataPayload)=>{
    return payload;
}