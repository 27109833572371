export default{
 "TYPER_2":"Typer 2",
 "PAGE_BETS":"Twoje Typy",
 "PAGE_SCOREBOARD":"Tabela wyników",
 "PAGE_TABLE":"Tabela meczów",
 "PAGE_MOD":"Panel Moderatora",
 "PAGE_ADMIN":"Panel Admina",
 "PAGE_PROFIL":"Profil",
 "LOGOUT":"Wyloguj",
 "LOGIN":"Zaloguj",

 "LOGIN_MODAL_HEADER":"Zaloguj się",
 "LOGIN_MODAL_DESC":"",
 "LOGIN_MODAL_INPUT_USERNAME":"Nazwa użytkownika",
 "LOGIN_MODAL_INPUT_PASSWORD":"Hasło",
 "LOGIN_MODAL_INPUT_REMEMBERME":"Zapamiętaj mnie",
 "LOGIN_MODAL_LOGIN":"Zaloguj",

 "LOGIN_MODAL_WRONG_PASSWORD":"Nieprawidłowa nazwa użytkownika lub hasło",
}