export default {
    "TABLE_HEADER":"All moderations",
    "TABLE_DESC":"All moderations.",

    "USER_NAME":"Username",
    "USER_SLUG":"User link",
    "TOURNAMENT_NAME":"Tournament name",
    "TOURNAMENT_SLUG":"Tournament link",

    "ADD_MODERATION_MODAL_HEADER":"Add new moderation",
    "ADD_MODERATION_MODAL_DESC":"Add new moderation.",
    "ADD_MODERATION_MODAL_INPUT_USERSLUG":"User link",
    "ADD_MODERATION_MODAL_INPUT_TOURNAMENTSLUG":"Tournament link",

    "DELETE_MODERATION_MODAL_HEADER":"Delete moderation",
    "DELETE_MODERATION_MODAL_DESC":"Delete moderation {{username}} from {{tournamentName}}.",
}