import { callAction, ReducerCollection } from "../../../store/core";
import { User } from "../model/user-data.model";
import { setAccessToken, SetAccessTokenPayload } from "./setAccessToken";
import { SetAuthDataPayload, setAuthData } from "./setAuthData";
import Cookies from 'universal-cookie';
import { setUserData, SetUserDataPayload } from "./setUserData";

let cookies = new Cookies();
let loggedIn = cookies.get('refresh_jwt_set')==="true";

export type AuthState={
    loggedIn: boolean;
    userData?:User;
    accessToken?:string;
};

const initialState:AuthState = {
    loggedIn,
}

enum Actions{
    "SET_AUTH_DATA"="SET_AUTH_DATA",
    "SET_ACCESS_TOKEN"="SET_ACCESS_TOKEN",
    "SET_USER_DATA"="SET_USER_DATA"
}

export class AuthReducer extends ReducerCollection<AuthState>{

    protected createActions(){
        return{
            [Actions.SET_AUTH_DATA]:setAuthData,
            [Actions.SET_ACCESS_TOKEN]:setAccessToken,
            [Actions.SET_USER_DATA]:setUserData,
        }
    }

    protected initState(){
        return initialState;
    }
}

export const callSetAuthData = (payload:SetAuthDataPayload)=>{
    return callAction(Actions.SET_AUTH_DATA,payload);
}

export const callSetAccessToken = (payload:SetAccessTokenPayload)=>{
    return callAction(Actions.SET_ACCESS_TOKEN,payload);
}

export const callSetUserData = (payload:SetUserDataPayload)=>{
    return callAction(Actions.SET_USER_DATA,payload);
}