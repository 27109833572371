import { StyleHTMLAttributes, useCallback, useEffect, useMemo, useState } from "react";
import { Match } from "../../modules/tournaments/model/match.model";
import { useTypedSelector } from "../../store/root";
import { useTranslate } from "react-translate";
import { findBet } from "./findBet";
import { timestampToDisplay } from "../../utils/time";
import { useTable } from "react-table";
import { Bet } from "../../modules/tournaments/model/bet.model";
import { BetPutData, UserSevice } from "../../modules/user/userService";
import { isUndefined } from "lodash";

type BetEdit = {
  matchId: number;
  score1?: number;
  score2?: number;
};

export const UpcomingMatchesTable: React.FC<{ matches: Array<Match> }> = (
  props
) => {
  const { matches } = props;
  const bets = useTypedSelector((state) => state.user.userBets);
  const t = useTranslate("betsPage");
  const [editMode, setEditMode] = useState<boolean>(false);
  const [betsEdit, setBetsEdit] = useState<Array<BetEdit>>([]);
  const betsPutData = useMemo(() => {
    if (!matches[0]) {
      return [];
    }
    return betsEdit
      .filter(
        (betEdit) =>
          !isUndefined(betEdit.score1) && !isUndefined(betEdit.score2)
      )
      .map((betEdit) => ({
        ...betEdit,
        tournamentId: matches[0].tournament,
      })) as Array<BetPutData>;
  }, [betsEdit, matches]);

  const handleBetScoreEdited = (
    team: 1 | 2,
    score: number,
    matchId: number
  ) => {
    const match = matches.find((match) => match._id === matchId);

    if (!match) {
      return;
    }

    const bet = findBet(bets, match);
    setBetsEdit((betsEdit) => {
      const newBetsEdit = [...betsEdit];
      const betEditIndex = newBetsEdit.findIndex(
        (betEdit) => betEdit.matchId === matchId
      );
      let newBetEdit: BetEdit;
      if (betEditIndex === -1) {
        //bet does not exist

        newBetEdit = { matchId };
        if (bet) {
          newBetEdit.score1 = bet.score1;
          newBetEdit.score2 = bet.score2;
        }
      } else {
        //bet exist
        newBetEdit = { ...newBetsEdit.splice(betEditIndex, 1)[0] };
      }
      if (team === 1) {
        newBetEdit.score1 = score;
      } else {
        newBetEdit.score2 = score;
      }
      newBetsEdit.push(newBetEdit);

      return newBetsEdit;
    });
  };

  const findBetEdit = (match: Match): BetEdit | undefined => {
    const matchId = match._id;
    return betsEdit.find((betEdit) => betEdit.matchId === matchId);
  };

  const putBets = useCallback((betsPutData:Array<BetPutData>) => {
    const userService = UserSevice.get();
    userService.betsPut(betsPutData).catch(e=>{
      alert(t("PUT_BET_ERROR"))
    });
  },[setEditMode]);

  const columns = useMemo(
    () => [
      {
        Header: t("COLUMN_TIMESTART"),
        accessor: "timeStart",
      },
      {
        Header: t("COLUMN_TEAM1"),
        accessor: "team1",
      },
      {
        Header: t("COLUMN_TEAM2"),
        accessor: "team2",
      },
    ],
    [t]
  );

  const data = useMemo(
    () =>
      matches.map((match) => {
        const bet: Bet | BetEdit | undefined = 
          findBetEdit(match) || findBet(bets, match);
        return {
          timeStart: timestampToDisplay(match.time_start),
          team1: match.team1,
          team2: match.team2,
          betScore1: isUndefined(bet?.score1) ? "" : bet?.score1,
          betScore2: isUndefined(bet?.score2) ? "" : bet?.score2,
          matchId: match._id,
        };
      }),
    [matches, betsEdit, bets,editMode]
  );

  const tableInstance = useTable({
    columns: columns as any,
    data,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  const buttonStyle = {
    margin: 5,
  };

  const inputStyle = {
    padding: 0,
    width: 40,
    textAlign: "center",
  };

  return (
    <div>
      <h3>{t("UPCOMING_MATCHES_TABLE_HEADER")}</h3>
      <p>{t("UPCOMING_MATCHES_TABLE_DESC")}</p>
      <div style={{ position: "relative" }}>
        <div className="position-sticky" style={{ display: "flex", top: 0 }}>
          {!editMode ? (
            <button
              className="btn btn-primary"
              style={buttonStyle}
              onClick={() => setEditMode(true)}
              type="submit"
            >
              {t("UPCOMING_MATCHES_EDIT")}
            </button>
          ) : (
            <>
              <button
                className="btn btn-outline-danger"
                style={buttonStyle}
                onClick={() => {setEditMode(false);
                  setBetsEdit([])}}
                type="submit"
              >
                {t("UPCOMING_MATCHES_REVERT")}
              </button>
              <button
                className="btn btn-primary"
                style={buttonStyle}
                onClick={()=>{
                  putBets(betsPutData);
                  setEditMode(false);
                  setBetsEdit([])
                }}
                type="submit"
              >
                {t("UPCOMING_MATCHES_SAVE")}
              </button>
            </>
          )}
        </div>
        <table className="table table-hover" {...getTableProps()}>
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th {...column.getHeaderProps()} scope="col">
                        {
                          // Render the header
                          column.render("Header")
                        }

                        {/* Add a sort direction indicator */}
                      </th>
                    ))
                  }
                  <th>{t("COLUMN_BET")}</th>
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row, i) => {
                // Prepare the row for display

                prepareRow(row);

                return (
                  // Apply the row props
                  <tr {...row.getRowProps()}>
                    {
                      // Loop over the rows cells
                      row.cells.map((cell) => {
                        // Apply the cell props
                        return (
                          <td {...cell.getCellProps()}>
                            {
                              // Render the cell contents
                              cell.render("Cell")
                            }
                          </td>
                        );
                      })
                    }
                    <td>
                      <div style={{ display: "flex" }}>
                        {!editMode ? (
                          `${row.original.betScore1}:${row.original.betScore2}`
                        ) : (
                          <>
                            <input
                              type="number"
                              onChange={(e) =>
                                handleBetScoreEdited(
                                  1,
                                  +e.target.value,
                                  row.original.matchId
                                )
                              }
                              value={row.original.betScore1}
                              style={{...inputStyle as any,borderColor:betsPutData.find(betPutData=>betPutData.matchId===row.original.matchId) ? "#198754" : undefined}}
                              className="form-control"
                              aria-label="Sizing example input"
                              aria-describedby="inputGroup-sizing-sm"
                            />
                            :
                            <input
                              type="number"
                              onChange={(e) =>
                                handleBetScoreEdited(
                                  2,
                                  +e.target.value,
                                  row.original.matchId
                                )
                              }
                              value={row.original.betScore2}            
                              style={{...inputStyle as any,borderColor:betsPutData.find(betPutData=>betPutData.matchId===row.original.matchId) ? "#198754" : undefined}}
                              className="form-control"
                              aria-label="Sizing example input"
                              aria-describedby="inputGroup-sizing-sm"
                            />
                          </>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UpcomingMatchesTable;
