import { useState } from "react";
import { useTranslate } from "react-translate";
import { useTypedSelector } from "../../store/root";
import PasswordChangeModal from "./PasswordChangeModal";
import ProfileUpdateModal from "./ProfileUpdateModal";

const ProfilEdit: React.FC = () => {
  const t = useTranslate("profilPage");
  const userData = useTypedSelector((state) => state.auth.userData);

  const [showPasswordChangeModal,setShowPasswordChangeModal] = useState<boolean>(false);
  const [showProfileEditModal,setShowProfileEditModal] = useState<boolean>(false);

  if (!userData) {
    return null;
  }

  const buttonStyle={
    margin:5
}

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className="card" style={{ width: "30rem" }}>
          <div className="card-header">{userData.display}</div>
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              {t("COLUMN_USERNAME")}:{userData.username}
            </li>
            <li className="list-group-item">
              {t("COLUMN_ADMIN")}:
              {userData.admin ? t("COLUMN_ADMIN_YES") : t("COLUMN_ADMIN_NO")}
            </li>
            <li className="list-group-item">
              {t("COLUMN_SLUG")}:{userData.slug}
            </li>
            <li className="list-group-item">
              {t("COLUMN_DISPLAY")}:{userData.display}
            </li>
          </ul>
        </div>
        
      </div>
      
      <div style={{display:"flex",justifyContent:"center",marginTop:20}}>

        <button className="btn btn-primary" style={buttonStyle} onClick={()=>setShowProfileEditModal(true)} type="submit">{t("EDIT_PROFILE")}</button>
        <button className="btn btn-primary" style={buttonStyle} onClick={()=>setShowPasswordChangeModal(true)} type="submit">{t("CHANGE_PASSWORD")}</button>
    </div>
    <PasswordChangeModal
    show={showPasswordChangeModal}
    onClose={()=>setShowPasswordChangeModal(false)}
    />
    <ProfileUpdateModal
    show={showProfileEditModal}
    onClose={()=>setShowProfileEditModal(false)}
    />
    </div>
  );
};

export default ProfilEdit;
