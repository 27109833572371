import {useTranslate} from "react-translate";
import { AdminService } from "../../modules/admin/admin.service";
import StandardModal, { StandardModalProps } from "../modal/StandardModal";

export type DeleteModerationModalData = {
    tournamentId:number,
    tournamentName:string,
    userId:number,
    userName:String,
}

const DeleteModerationModal: React.FC<StandardModalProps&{data:DeleteModerationModalData|null}>=(props)=>{

    const {data,onClose,show} = props;
    const t = useTranslate("moderationsTable");

    if(!data){
        return null;
    }

    const deleteModeration = (tournamentId:number,userId:number)=>{
        const adminService = AdminService.get();
        adminService.moderationDelete(tournamentId,userId);
    }

    return (
        <StandardModal
        show={show}
        onExit={onClose}
        onSave={()=>{
            onClose();
            deleteModeration(data.tournamentId,data.userId);
        }}
        saveEnabled={true}
        title={t("DELETE_MODERATION_MODAL_HEADER")}
        >
            {t("DELETE_MODERATION_MODAL_DESC",{username:data.userName,tournamentName:data.tournamentName})}
        </StandardModal>

    );
}

export default DeleteModerationModal;