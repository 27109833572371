import { Team } from '../models/team.model'
import {TeamsState} from '.'
import * as _ from 'lodash'

export type AddTeamPayload = {
    team:Team
}

export function addTeam(state: TeamsState,payload:AddTeamPayload): TeamsState{
    const teams = _.cloneDeep(state.teams);
    teams.push(payload.team)
    return {teams}
}