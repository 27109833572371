import { callAction, ReducerCollection } from "../../../store/core";
import { User } from "../../auth/model/user-data.model";
import { Bet } from "../model/bet.model";
import { Match } from "../model/match.model";
import { Tournament } from "../model/tournament.model";
import { setTournamentsData, SetTournamentsDataPayload } from "./setTournamentsData";

export type TournamentsState = {
    tournaments:Array<Tournament>,
    tournamentsParticipate:Array<Tournament>,
    matches:Array<Match>,
    bets:Array<Bet>,
    users:Array<User>
}

const initialState:TournamentsState = {
    tournaments:[],
    tournamentsParticipate:[],
    matches:[],
    bets:[],
    users:[]
}

enum Actions {
    "SET_TOURNAMETS_DATA"="SET_TOURNAMETS_DATA",
}

export class TournamentsReducer extends ReducerCollection<TournamentsState>{

    protected createActions(){
        return{
            [Actions.SET_TOURNAMETS_DATA]:setTournamentsData
        }
    }

    protected initState(){
        return initialState;
    }

}

export const callSetTournamentsData=(payload:SetTournamentsDataPayload)=>{
    return callAction(Actions.SET_TOURNAMETS_DATA,payload);
}

