import { Store } from "redux";
import { Team } from "./models/team.model";
import { callAddTeam, callDeleteTeam } from "./store";

export class TeamsService {
  private constructor(private store:Store) {}

  private static self: TeamsService;
  static init(store:Store) {
    this.self = new this(store);
    return this.get();
  }
  static get() {
    if (!this.self) {
      throw Error("Not initialized yet.");
    }
    return this.self;
  }

  private dispatchAddTeam(team:Team){
    const dispatch = this.store.dispatch;
    return dispatch(callAddTeam({team}))
  }

  private dispatchDeleteTeam(team:Team){
    const dispatch = this.store.dispatch;
    return dispatch(callDeleteTeam({team}))
  }

  addTeam(team:Team){
    this.dispatchAddTeam(team);
  }

  deleteTeam(team:Team){
    this.dispatchDeleteTeam(team);
  }

}
