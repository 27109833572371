import { useEffect, useMemo, useState } from "react";
import { ToastMessage as ToastMessageModel } from "./models/toast-message.model";
import ToastMessage from "./components/ToastMessage";
import { useTypedSelector } from "../../store/root";

const ToastMessagesProvider: React.FC = () => {

  const [hidedMessages,setHidedMessages] = useState<Array<string>>([])
  const messages:Array<ToastMessageModel> = useTypedSelector(state=>state.toastMessages.toastMessages);

  const pushHidedMessage = (id:string)=>{
      setHidedMessages((curr)=>[...curr,id])
  }

  const toastContainerStyle = {
    marginTop: 20,
  };

  return (
    <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 11 }}>

     {messages.map((message)=>(
        <div key={message.id} style={toastContainerStyle}>
        <ToastMessage
        id={message.id}
        onClose={() => pushHidedMessage(message.id)}
        show={!hidedMessages.includes(message.id)}
        data={message.data}
        />
        </div>
     ))}

    </div>
  );
};

export default ToastMessagesProvider;
