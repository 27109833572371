import axios, { AxiosError } from "axios";
import { Store } from "redux";
import { FULL_URL } from "../../config/server";
import { ReducersState } from "../../store/root";
import { User } from "./model/user-data.model";
import { callSetAccessToken, callSetAuthData } from "./store";

const ACCESS_TOKEN_LIFETIME = 300;

const wrongPasswordMessage = "WRONG_PASSWORD";

export class AuthService {
  private constructor(private store: Store) {
    this.setSubscribers();
    this.checkIfRememberedMe();
  }

  private static self: AuthService;
  static init(store: Store) {
    this.self = new this(store);
    return this.get();
  }
  static get() {
    if (!this.self) {
      throw Error("Not initialized yet.");
    }
    return this.self;
  }

  async loginLocal(username: string, password: string, rememberMe: boolean) {
    let wrongPassword = false;
    const userData = (await this.sendLoginLocalRequest(
      username,
      password,
      rememberMe
    ).catch((e: Error) => {
      if (e.message === wrongPasswordMessage) {
        wrongPassword = true;
        return;
      }
      throw e;
    })) as User;
    if (wrongPassword) {
      return false;
    }
    const accessToken = await this.sendRefreshAccessTokenRequest();
    this.dispatchSetAuthData(true, userData, accessToken);
    return true;
  }

  async logout() {
    await this.sendLogoutRequest();
    this.dispatchSetAuthData(false);
  }

  async changePassword(
    username: string,
    password: string,
    newPassword: string,
    rememberMe: boolean
  ) {
    let wrongPassword = false;
    const userData = (await this.sendChangePasswordRequest(
      username,
      password,
      newPassword,
      rememberMe
    ).catch((e: Error) => {
      if (e.message === wrongPasswordMessage) {
        wrongPassword = true;
        return;
      }
      throw e;
    })) as User;
    if (wrongPassword) {
      return false;
    }
    const accessToken = await this.sendRefreshAccessTokenRequest();
    this.dispatchSetAuthData(true, userData, accessToken);
    return true;
  }

  getAccessToken(){
    const accessToken = (this.store.getState() as ReducersState).auth.accessToken
    if(!accessToken){
      throw Error("No access token stored.")
    }
    return accessToken;
  }

  private async checkIfRememberedMe(){
    const state = this.store.getState() as ReducersState;
    if(!(state.auth.loggedIn)){
      //not remembered me
      return;
    }
    const accessToken = await this.sendRefreshAccessTokenRequest();
    const userData = await this.getMe(accessToken).catch((e)=>{
      this.dispatchSetAuthData(false)
      throw e;
    });
    this.dispatchSetAuthData(true,userData,accessToken);
  }

  async getMe(gotAccessToken?:string){

    const accessToken = gotAccessToken || this.getAccessToken();
    return await this.sendGetMeRequest(accessToken);
  }

  private setSubscribers() {
    let prevLoggedIn = false;

    const refreshAccessToken = async () => {
      const accessToken = await this.sendRefreshAccessTokenRequest();
      this.dispatchSetAccessToken(accessToken);
    };

    let interval: ReturnType<typeof setInterval> | undefined;

    this.store.subscribe(() => {
      const state = this.store.getState() as ReducersState;
      let loggedIn = state.auth.loggedIn;
      if (loggedIn === prevLoggedIn) {
        return;
      }
      prevLoggedIn = loggedIn;

      if (loggedIn) {
        interval = setInterval(
          refreshAccessToken,
          (ACCESS_TOKEN_LIFETIME - 10) * 1000
        );
      } else {
        if (interval) {
          clearInterval(interval);
          interval = undefined;
        }
      }
    });
  }

  private dispatchSetAuthData(
    loggedIn: boolean,
    userData?: User,
    accessToken?: string
  ) {
    this.store.dispatch(callSetAuthData({ loggedIn, userData, accessToken }));
  }

  private dispatchSetAccessToken(accessToken: string) {
    this.store.dispatch(callSetAccessToken({ accessToken }));
  }

  private async sendLoginLocalRequest(
    username: string,
    password: string,
    rememberMe: boolean
  ) {
    const response = 
      (await axios
        .post(`${FULL_URL}api/auth/login`, {
          username,
          password,
          rememberMe,
        })
        .catch((e: AxiosError) => {
          if (e.response?.status === 401) {
            throw Error(wrongPasswordMessage);
          }
          throw e;
        })
    );
    return response.data as User;
  }

  private async sendRefreshAccessTokenRequest() {
    return (await axios.post(`${FULL_URL}api/auth/refresh`, {}))
      .data.access_token_jwt as string;
  }

  private async sendLogoutRequest() {
    return await axios.post(`${FULL_URL}api/auth/logout`, {});
  }

  private async sendChangePasswordRequest(
    username: string,
    password: string,
    newPassword: string,
    rememberMe: boolean
  ) {
    return (
      await axios
        .post(`${FULL_URL}api/auth/change-password`, {
          username,
          password,
          newPassword,
          rememberMe,
        })
        .catch((e: AxiosError) => {
          if (e.response?.status === 401) {
            throw Error(wrongPasswordMessage);
          }
          throw e;
        })
    ).data as User;
  }
  
  private async sendGetMeRequest(accessToken:string){
    return await (await axios.get(`${FULL_URL}api/users-me`,{headers:{
    'Authorization': `Bearer ${accessToken}`
    }})).data as User;
  }
}
