import { AvailableLanguageTags } from "../../modules/translate/models/available-language-tags";
import { TranslateService } from "../../modules/translate/translate.service";
import { useTypedSelector } from "../../store/root";

const LanguageSelect:React.FC = ()=>{

    const languageTag = useTypedSelector(state=>state.translate.languageTag);
    const availableLanguageTags = Object.values(AvailableLanguageTags)

    const changeLanguage = (languageTag:AvailableLanguageTags)=>{
        const translateService = TranslateService.get();
        translateService.changeLanguage(languageTag);
    }

    return (
        
        <li className="nav-item dropdown">
        <a
          className="nav-link dropdown-toggle"
          href="#"
          id="languageDropdown"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
            {languageTag}
        </a>
        <ul className="dropdown-menu" aria-labelledby="languageDropdown">
            {
                availableLanguageTags.map((languageTag,i)=>(
                    
                    <li key={i}>
                    <a className="dropdown-item" onClick={e=>{e.preventDefault(); changeLanguage(languageTag)}} href="#">
                    {languageTag}
                    </a>
                </li>
                ))

            }
        </ul>
        </li>
    );
}

export default LanguageSelect;