import axios, { AxiosResponse } from "axios";
import { Store } from "redux";
import { FULL_URL } from "../../config/server";
import { ReducersState } from "../../store/root";
import { AuthService } from "../auth/auth.service";
import { User } from "../auth/model/user-data.model";
import { callSetUserData } from "../auth/store";
import { Bet } from "../tournaments/model/bet.model";
import { callSetUserBets } from "./store";

export type BetPutData={
  tournamentId:number,matchId:number,score1:number,score2:number
}

export class UserSevice {
    private constructor(private store: Store,private authService:AuthService) {
      this.setSubscribers();
    }
  
    private static self: UserSevice;
    static init(store: Store,authService:AuthService) {
      this.self = new this(store,authService);
      return this.get();
    }
    static get() {
      if (!this.self) {
        throw Error("Not initialized yet.");
      }
      return this.self;
    }

    private setSubscribers(){
      let prevUserSlug:string=""

      this.store.subscribe(()=>{
        const state = this.store.getState() as ReducersState;
        const userSlug:string = (state.auth.accessToken && state.auth.userData?.username) || "";
        if(prevUserSlug===userSlug){
          return;
        }
        prevUserSlug=userSlug;
        if(userSlug){
        this.updateBets();
        }else{
          this.clearBets();
        }
      })
    }

    async userUpdate(display:string){
      const accessToken = this.authService.getAccessToken();
      await this.sendUserUpdateRequest(accessToken,display);
      const userData = await this.authService.getMe();
      this.dispatchUserData(userData)
    }

    async userChangePassword(username:string,password:string,newPassword:string){
      const accessToken = this.authService.getAccessToken();
      let invalidCredentials=false;
      await this.sendUserChangePasswordRequest(accessToken,username,password,newPassword).catch(e=>{
        if(e.response.status===401){
          invalidCredentials=true;
        }
        else{
          throw e;
        }
      }) as AxiosResponse<any>;
      if(!invalidCredentials){
        return true;
      }else{
        return false;
      }
    }

    //this is variable used in fix below
    private lastBetPutArray:Array<BetPutData>|null=null;

    async betsPut(betsPut:Array<BetPutData>){
      this.lastBetPutArray=betsPut;
      const accessToken = this.authService.getAccessToken();
      let failed = false;

      await Promise.all(betsPut.map(bet=>this.sentBetPutRequest(accessToken,bet))).catch(()=>{
        failed = true;
      })
      
      const newBetsData = await this.updateBets();
      if(failed){
        throw new Error("Bet put failed")
      }
      
      //bugfix serverside: Sometimes server does not save bets propably. This code check if bets are ok, and if not, reexecute function (unless new bets has been put)
      const notSavedBet = betsPut.filter(betPut=>{
        const matchId = betPut.matchId;
        const matchSavedBet = newBetsData.find(bet=>+bet.match===matchId);
        return !(betPut.score1===matchSavedBet?.score1&&betPut.score2===matchSavedBet.score2);
      }) 
      if(notSavedBet.length&&this.lastBetPutArray===betsPut){
        this.betsPut(notSavedBet);
      }
    }
    

    private clearBets(){
      this.dispatchSetUserBets([]);
    }

    private async updateBets(){
      const accessToken = this.authService.getAccessToken();
      const userBets = await this.sentBetListByUserRequest(accessToken);
      this.dispatchSetUserBets(userBets);
      return userBets;
    }

    private dispatchUserData(userData:User){
      return this.store.dispatch(callSetUserData({userData}))
    }

    private dispatchSetUserBets(userBets:Array<Bet>){
      return this.store.dispatch(callSetUserBets({userBets}))
    }

    private async sendUserUpdateRequest(accessToken:string,display:string){
      return (await axios.put(`${FULL_URL}api/users-me`, {display},{headers:{
        'Authorization': `Bearer ${accessToken}`
        }}))
    }

    private async sendUserChangePasswordRequest(accessToken:string,username:string,password:string,newPassword:string){
      return await axios.post(`${FULL_URL}api/auth/change-password`, {username,password,newPassword,rememberMe:false},{headers:{
        'Authorization': `Bearer ${accessToken}`
        }})
    }

    private async sentBetPutRequest(accessToken:string,data:BetPutData){
      return (await axios.put(`${FULL_URL}api/tournaments/${data.tournamentId}/matches/${data.matchId}/bets`, {score1:data.score1,score2:data.score2},{headers:{
        'Authorization': `Bearer ${accessToken}`
        }}))
    }

    private async sentBetListByUserRequest(accessToken:string){
      return (await axios.get(`${FULL_URL}api/bets`, {headers:{
        'Authorization': `Bearer ${accessToken}`
        }})).data as Array<Bet>
    }
}