import { callAction, ReducerCollection } from "../../../store/core";
import { Tournament } from "../../tournaments/model/tournament.model";
import { setModeratedTournaments, SetModeratedTournamentsPayload } from "./setModeratedTournaments";

export type ModerationsState = {
    moderatedTournaments: Array<Tournament>
}

const initialState: ModerationsState={
    moderatedTournaments:[]
}

enum Actions {
    "SET_MODERATED_TOURNAMENTS"="SET_MODERATED_TOURNAMENTS"
}

export class ModerationsReducer extends ReducerCollection<ModerationsState>{
    protected createActions(){
        return{
            [Actions.SET_MODERATED_TOURNAMENTS]:setModeratedTournaments
        }
    }
    protected initState(){
        return initialState;
    }
}

export const callSetModeratedTournaments = (payload:SetModeratedTournamentsPayload)=>{
    return callAction(Actions.SET_MODERATED_TOURNAMENTS,payload);
}