export default{
 "TYPER_2":"Typer 2",
 "PAGE_BETS":"Your bets",
 "PAGE_SCOREBOARD":"Scoreboard",
 "PAGE_TABLE":"Table",
 "PAGE_MOD":"Moderator Panel",
 "PAGE_ADMIN":"Admin Panel",
 "PAGE_PROFIL":"Profil",
 "LOGOUT":"Logout",
 "LOGIN":"Login",

 "LOGIN_MODAL_HEADER":"Login",
 "LOGIN_MODAL_DESC":"",
 "LOGIN_MODAL_INPUT_USERNAME":"Username",
 "LOGIN_MODAL_INPUT_PASSWORD":"Password",
 "LOGIN_MODAL_INPUT_REMEMBERME":"Remember me",
 "LOGIN_MODAL_LOGIN":"Login",

 "LOGIN_MODAL_WRONG_PASSWORD":"Wrong username or password",

}