import StandardModal, { StandardModalProps } from "../modal/StandardModal";
import { useState } from "react";
import { useTranslate } from "react-translate";
import { AdminService } from "../../modules/admin/admin.service";

export type PasswordChangeModalData={
        userId:number,
        username:string,
}

const PasswordChangeModal: React.FC<StandardModalProps&{data:PasswordChangeModalData|undefined}>=(props)=>{

    const { show, onClose, data } = props;
    const t = useTranslate("usersTable");
    const [password, setPassword] = useState<string>("");

    if(!data){
        return null;
    }

    const passwordChangeAdmin = ()=>{
        const adminService = AdminService.get();
        adminService.changePasswordAdmin(data.userId,password);
    }

    return (
        <StandardModal
          show={show}
          onExit={onClose}
          onSave={() => {
            onClose();
            passwordChangeAdmin();
          }}
          saveEnabled={true}
          title={t("PASSWORD_CHANGE_MODAL_HEADER")}
        >
          <p>{t("PASSWORD_CHANGE_MODAL_DESC",{username:data.username})}</p>
          <div className="input-group mb-3">
            <span style={{width:"40%"}} className="input-group-text" id="basic-addon3">
            {t("PASSWORD_CHANGE_MODAL_INPUT_PASSWORD")}
    
            </span>
            <input
              value={password}
              onChange={(e)=>setPassword(e.target.value)}
              type="text"
              className="form-control"
              id="basic-url"
              aria-describedby="basic-addon3"
            />
          </div>
        </StandardModal>
        
    );
}

export default PasswordChangeModal;